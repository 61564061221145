import React,{useEffect, useState} from 'react';
import translate from '../../Translations/index';
import Modal from 'react-modal';
import StripeLib from '../Library/stripe';
import Loading from '../../../GlobalComponents/Loading';
import {withApollo,useQuery} from 'react-apollo';
import {GET_RENEW_MEMBERSHIP_DATA} from '../Queries/payu';
import close from '../../../Public/Images/Icons/close.webp';
import moment from 'moment';
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#555555",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "18px",
      "::placeholder": {
        color: "#7d7d7d",
      },
      backgroundColor:"#F5F3ED"
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
  hidePostalCode:true
};

const ReNewButton = (props) => {

    const stripeLib = new StripeLib(props);

    const stripe = useStripe();
    const elements = useElements();

    const [showCardPayment, setShowCardPayment] = useState(false);
    const [formData, setFormData] = useState({
        customerID:0,
        domainID:0,
        email:"",
        name:"",
        surname:"",
        street:"",
        city:"",
        zip:"",
        countryID:60,
        tel:"",
        creditCard:"",
        payuCond:false,
        cvv:"",
        expMonth:"",
        expYear:"",
        membershipDurationID:0,
        membershipPrice:0
    });
    const [domainData, setDomainData] = useState(null);
    const [showCreditCardPayment, setCreditCardPayment] = useState(false);
    const [load, setLoading] = useState(false);
    const [succesPayment, setSuccesPayment] = useState(false);
    const [paymentError, setPaymentError] = useState(null);
    const [wholeError, setWholeError] = useState(null);
    const [err, setError] = useState("");

    const {data,loading,error} = useQuery(GET_RENEW_MEMBERSHIP_DATA,{
        fetchPolicy: 'network-only',
        variables:{
            lang: (domainData ? domainData.lang : translate.locale),
            domainID:formData.domainID,
            regCode:props.regCode
        },
        onCompleted:(d)=>{

            var membershipPrice = 0;
            var membershipDurationID = 0;

            if(d.customerByRegCode){

                var checkMem = true;
                var memTo;

                if(d.customerByRegCode.membershipTo){
                    memTo = d.customerByRegCode.membershipTo.split(".");

                    memTo = memTo[2] + "-" + memTo[1] + "-" + memTo[0] + " 23:59:59";

                    if(props.fromLink && moment() < moment(memTo)) checkMem = false;
                }

                if(checkMem){

                    for(const item of d.allAdminMembershipDurations){

                        if(!props.onlyAnnualMembership && d.customerByRegCode.membershipDuration == item.membershipDurationID){

                            membershipDurationID = d.customerByRegCode.membershipDuration;

                            if(props.onlyDiscountPrice && moment(memTo) > moment()){
                                membershipPrice = item.discount;
                            }else{

                                if(item.discount != 0 && moment(item.discountValidityFrom) <= moment() && moment(item.discountValidityTo) >= moment()){
                                    membershipPrice = item.discount;
                                }else{

                                    if(item.discount != 0 && d.customerByRegCode.membershipPrice == item.discount && d.customerByRegCode.lastCompletedCustomerPayment && moment(d.customerByRegCode.lastCompletedCustomerPayment.dateTo).hours(23).minutes(59).seconds(59).add(item.discountRepeatPaymentDays,"d") >= moment()){
                                        membershipPrice = item.discount;
                                    }else{
                                        membershipPrice = item.value;
                                    }

                                }  
                            }
                        }

                        if(props.onlyAnnualMembership && item.length == 12){

                            membershipDurationID = item.membershipDurationID;

                            if(props.onlyDiscountPrice && moment(memTo) > moment()){
                                if(d.customerByRegCode.membershipPrice == 2970)
                                    membershipPrice = item.discount;
                                else 
                                    membershipPrice = item.value;
                            }else{

                                if(item.discount != 0 && moment(item.discountValidityFrom) <= moment() && moment(item.discountValidityTo) >= moment()){
                                    membershipPrice = item.discount;
                                }else{

                                    if(item.discount != 0 && d.customerByRegCode.membershipPrice == item.discount && d.customerByRegCode.lastCompletedCustomerPayment && moment(d.customerByRegCode.lastCompletedCustomerPayment.dateTo).hours(23).minutes(59).seconds(59).add(item.discountRepeatPaymentDays,"d") >= moment()){
                                        membershipPrice = item.discount;
                                    }else{
                                        membershipPrice = item.value;
                                    }

                                } 
                            }

                            break;
                        }
                    }
                    
                    setFormData({
                        ...formData,
                        customerID:      d.customerByRegCode.customerID,
                        domainID:        (formData.domainID != 0 && formData.domainID !=  d.customerByRegCode.domainID ? formData.domainID : d.customerByRegCode.domainID),
                        email:           d.customerByRegCode.email,
                        name:            d.customerByRegCode.name,
                        surname:         d.customerByRegCode.surname,
                        street:          d.customerByRegCode.street,
                        city:            d.customerByRegCode.city,
                        zip:             d.customerByRegCode.zip,
                        countryID:       d.customerByRegCode.countryID,
                        tel:             d.customerByRegCode.tel,
                        membershipPrice:      membershipPrice,
                        membershipDurationID: membershipDurationID
                    })

                    setDomainData(d.domainData);
                    if(d.domainData)translate.locale = d.domainData.lang;

                }else{
                    setWholeError(translate.t("AllreadyHaveMembership"));
                }

            }else{
                setWholeError(translate.t("CustomerNotExists"));
            }
        }
    });

    useEffect(() =>{
        if(props.autoOpenModal){
            setShowCardPayment(true);
        }
    },[])

    const repeatPament = () => {
        setPaymentError("");
        setCreditCardPayment(true);
    }


    var loadingData = false;
    var errorMessage = "";

    if(loading || load){
        loadingData = true;
    }

    if(error || err){
        errorMessage = (error || err);
    }

    var custMembershipTo = null;
                
    if(data && data.customerByRegCode && data.customerByRegCode.membershipTo)
    {   
        custMembershipTo = data.customerByRegCode.membershipTo.split(".");
        custMembershipTo = custMembershipTo[2] + "-" + custMembershipTo[1] + "-" + custMembershipTo[0]; 
    }

    return(
        <>
            <button onClick={() => setShowCardPayment(true)} className="btn btn-primary">
                {props.withBorder ?
                    <div>{props.text}</div>
                :
                    props.text
                }
            </button>
            <Modal
                className="Modal__Bootstrap modal-lg modal-dialog"
                isOpen={showCardPayment}
                onRequestClose={() => setShowCardPayment(false)}
                contentLabel="Platební brána"
            >
                <div className="modal-content">
                    <div className="modal-header">
                        {(data && data.customerByRegCode && data.customerByRegCode.membershipDuration == 0 ? translate.t("BuyMembership") : translate.t("ReNewMembership"))}
                        <img src={close} className="close" onClick={() => setShowCardPayment(false)} />
                    </div>
                    
                    <div className="modal-body">

                        {!wholeError ?

                            (!loadingData && data.customerByRegCode ?
                                
                                (paymentError ?

                                    <>

                                        <div className="alert alert-danger text-center">{paymentError}</div>
                                        <button onClick={() => repeatPament()} className="btn btn-primary btn-large btn-block">{translate.t("RepeatPayment")}</button>

                                    </>

                            
                                :
                                    (succesPayment ?

                                        <div className="alert alert-success text-center">{translate.t("ThanksPaymentIsOkMembershipActive")}</div>
                                
                                    :
                                        (!showCreditCardPayment ? 

                                            <>

                                                <div className="payu-form">
                                                    
                                                    <div className="row">
                                                        <div className="col-12 col-sm-6">
                                                            <div className="form-group">
                                                                <div className="input">
                                                                    <label>{translate.t("Name")}</label>
                                                                    <div>
                                                                        <input type="text" name="name" className="form-control" value={formData.name} onChange={(e) => stripeLib.formHandle(e,formData,setFormData)} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-sm-6">
                                                            <div className="form-group">
                                                                <div className="input">
                                                                    <label>{translate.t("Surname")}</label>
                                                                    <div>
                                                                        <input type="text" name="surname" className="form-control" value={formData.surname} onChange={(e) => stripeLib.formHandle(e,formData,setFormData)} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-sm-8">
                                                            <div className="form-group">
                                                                <div className="input">
                                                                    <label>{translate.t("Street")}</label>
                                                                    <div>
                                                                        <input type="text" name="street" className="form-control" value={formData.street} onChange={(e) => stripeLib.formHandle(e,formData,setFormData)} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-sm-4">
                                                            <div className="form-group">
                                                                <div className="input">
                                                                    <label>{translate.t("Zip")}</label>
                                                                    <div>
                                                                        <input type="text" name="zip" className="form-control" value={formData.zip} onChange={(e) => stripeLib.formHandle(e,formData,setFormData)} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-sm-6">
                                                            <div className="form-group">
                                                                <div className="input">
                                                                    <label>{translate.t("City")}</label>
                                                                    <div>
                                                                        <input type="text" name="city" className="form-control" value={formData.city} onChange={(e) => stripeLib.formHandle(e,formData,setFormData)} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-sm-6">
                                                            <div className="form-group">
                                                                <div className="input">
                                                                    <label>{translate.t("Country")}</label>
                                                                    <div>
                                                                        <select className="form-control" name="countryID" value={formData.countryID} onChange={(e) => stripeLib.formHandle(e,formData,setFormData)}>
                                                                            {data.allCountries.map((item,index) => (
                                                                                <option value={item.countryID} key={index}>{item.name}</option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-sm-6">
                                                            <div className="form-group">
                                                                <div className="input">
                                                                    <label>{translate.t("Phone")}</label>
                                                                    <div>
                                                                        <input type="text" name="tel" className="form-control payu-card-number" value={formData.tel} onChange={(e) => stripeLib.formHandle(e,formData,setFormData)} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {props.firstPayment ?
                                                            <div className="col-12 col-sm-6">
                                                                <div className="form-group">
                                                                    <div className="input">
                                                                        <label>{translate.t("Currency")}</label>
                                                                        <div>
                                                                        <select className="form-control" name="domainID" value={formData.domainID} onChange={(e) => stripeLib.formHandle(e,formData,setFormData)}>
                                                                            
                                                                            <option value={4}>CZK</option>
                                                                            <option value={5}>EUR</option>
                                                                            
                                                                        </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        :null}
                                                    </div>

                                                    {!data.customerByRegCode.lastCompletedCustomerPayment ?
                                                        <p className="membership-items-header">TÝDEN ZDARMA v Astrální akademii</p>
                                                    :
                                                        <p className="membership-items-header">Vyber si typ členství</p>
                                                    }

                                                    <div className="row">

                                                        {data.allAdminMembershipDurations && data.allAdminMembershipDurations.map((item,index) => {

                                                            var price = 0;
                                                            var fullPrice = null;

                                                            if(props.onlyDiscountPrice && moment(data.customerByRegCode.membershipTo) > moment()){
                                                                if(data.customerByRegCode.membershipPrice == 2970)
                                                                {
                                                                    price = item.discount;
                                                                    fullPrice = item.value;
                                                                }
                                                                else
                                                                {
                                                                    price = item.value;
                                                                }
                                                            }else{

                                                                if(data.customerByRegCode.membershipTo == null){

                                                                    if(item.discount != 0 && moment(item.discountValidityFrom) <= moment() && moment(item.discountValidityTo) >= moment()){
                                                                        price = item.discount;
                                                                        fullPrice = item.value;
                                                                    }else{

                                                                        if(item.length == 1){

                                                                            if(item.firstValue != 0){
                                                                                price = item.firstValue;
                                                                            }else{
                                                                                price = item.value;
                                                                            }
            
                                                                        }else{
                                                                            price = item.value;
                                                                        }

                                                                    }
                                                                    
                                                                }else{

                                                                    if(item.discount != 0 && moment(item.discountValidityFrom) <= moment() && moment(item.discountValidityTo) >= moment()){
                                                                        price = item.discount;
                                                                        fullPrice = item.value;
                                                                    }else{

                                                                        if(item.discount != 0 && data.customerByRegCode.membershipPrice == item.discount && data.customerByRegCode.lastCompletedCustomerPayment && moment(data.customerByRegCode.lastCompletedCustomerPayment.dateTo).hours(23).minutes(59).seconds(59).add(item.discountRepeatPaymentDays,"d") >= moment()){
                                                                            price = item.discount;
                                                                            fullPrice = item.value;
                                                                        }else{

                                                                            price = item.value;
                                                                            
                                                                        }

                                                                    }

                                                                }

                                                            }

                                                            if(!props.onlyAnnualMembership || (props.onlyAnnualMembership && item.length == 12)){

                                                                return(
                                                                    <div key={index} className={(data.allAdminMembershipDurations.length > 1 && !props.onlyAnnualMembership ? "col-12 col-sm-6 mb-3" : "col-12 mb-3")}>
                                                                    
                                                                        <div onClick={() => setFormData({
                                                                            ...formData,
                                                                            membershipPrice:      price,
                                                                            membershipDurationID: item.membershipDurationID
                                                                        })} className={"membership-item" + (formData.membershipDurationID == item.membershipDurationID ? " selected" : "")}>

                                                                            <div className="membership-content">
                                                                                <div className="item">{item.name}</div>
                                                                                {!data.customerByRegCode.lastCompletedCustomerPayment ?
                                                                                    <div className="desc">Po týdnu zdarma vám bude účtováno {price} {(domainData ? domainData.currencyTitle : data.customerByRegCode.domain.currencyTitle)}</div>
                                                                                :
                                                                                
                                                                                    (item.description != "" && !props.onlyDiscountPrice ? 
                                                                                        <div className="desc" dangerouslySetInnerHTML={{__html:item.description}}></div> 
                                                                                    : null)
                                                                                }
                                                                            </div>

                                                                            {data.customerByRegCode.membershipTo == null && item.length == 1 && item.firstValue != 0 ?

                                                                                
                                                                                <>
                                                                                    <div className="price">
                                                                                        <div><span>{translate.t("firstMonth")}:</span> {price} {(domainData ? domainData.currencyTitle : data.customerByRegCode.domain.currencyTitle)}</div>
                                                                                        <div><span>{translate.t("nextMonths")}:</span> {item.value} {(domainData ? domainData.currencyTitle : data.customerByRegCode.domain.currencyTitle)}</div>
                                                                                    </div>
                                                                                </>
                                                                                
                                                                                
                                                                            :
                                                                                <>

                                                                                    {data.customerByRegCode.membershipTo == null && item.trialPeriod != 0 ?
                                                                                    
                                                                                        <div className="price">
                                                                                            {0} {(domainData ? domainData.currencyTitle : data.customerByRegCode.domain.currencyTitle)}
                                                                                        </div>

                                                                                    :
                                                                                    
                                                                                        (data.customerByRegCode.affiliateGiftCustomer && data.customerByRegCode.affiliateGiftCustomer.usingDiscount == 1 && moment() < moment(custMembershipTo).hours(23).minutes(59).seconds(59).add(data.customerByRegCode.affiliateGiftCustomer.discountRepeatPaymentDays,'d') && fullPrice == null ?

                                                                                            <div className="price">
                                                                                                <div className="previous-price"> {item.value} {(domainData ? domainData.currencyTitle : data.customerByRegCode.domain.currencyTitle)}</div>
                                                                                                {item.value - (data.customerByRegCode.affiliateGiftCustomer.membershipDurationID == item.membershipDurationID ? data.customerByRegCode.affiliateGiftCustomer.discount : item.affiliateGiftMembershipDiscount.discount)} {(domainData ? domainData.currencyTitle : data.customerByRegCode.domain.currencyTitle)}
                                                                                            </div>
                                                                                        :
                                                                                            <div className="price">
                                                                                                {fullPrice != null ? <div className="previous-price"> {fullPrice} {(domainData ? domainData.currencyTitle : data.customerByRegCode.domain.currencyTitle)}</div> : null}
                                                                                                {price} {(domainData ? domainData.currencyTitle : data.customerByRegCode.domain.currencyTitle)}
                                                                                            </div>
                                                                                        ) 
                                                                                    }
                                                                                </>
                                                                            }

                                                                        </div>

                                                                    </div>
                                                                )
                                                            }else{
                                                                return("");
                                                            }

                                                        })}

                                                        

                                                    </div>

                                                    <div className="text-center order">

                                                        {props.waitToTheEndOfFreeTrial ?
                                                            <div className="alert alert-danger">
                                                                Jsme rádi, že chceš pokračovat s námi dále a líbí se ti u nás. Nyní ti však ještě běží 7 denní bezplatné studium, své členství si zde můžeš prodloužit 8. den.
                                                            </div>
                                                        :
                                                            <button className="btn btn-primary btn-large btn-block" onClick={(e) => stripeLib.continueToPayment(formData,setCreditCardPayment,setError)} >{translate.t("ContinueToPayment")}</button>
                                                        }
                                                        
                                                        {errorMessage != "" &&
                                                            <div className="alert alert-danger mt-3">{errorMessage}</div>
                                                        } 

                                                        {data.customerByRegCode.domain.lang == "cz" && !props.firstPayment && !props.waitToTheEndOfFreeTrial ?
                                                            <p className="text-center">{translate.t("DoYouWantToPayInEuro1")} <a href="mailto:prvni@astralniakademie.cz">prvni@astralniakademie.cz</a> {translate.t("DoYouWantToPayInEuro2")}</p>
                                                        :null}
                                                    </div>
                                                    
                                                </div>  
    

                                            </>

                                        :    
                                            <>

                                                <div className="payu-desc">
                                                    <p className="text-center">{translate.t("PaymentBy")} <a target="_blank" href="https://www.stripe.com">Stripe</a>. {translate.t("AllCardData")}  <strong>{translate.t("NotBeKept")}</strong>{translate.t("SavedInPayu")}</p>
                                                    <p className="text-center mb-5">{translate.t("PleaseFillCardData")}</p>
                                                    <CardElement options={CARD_ELEMENT_OPTIONS} />
                                                    <button className="btn btn-primary btn-large btn-block" onClick={(e) => stripeLib.makeOrder(e,setPaymentError,stripe,elements,CardElement,formData,setSuccesPayment,setLoading,setError,props.client,props.regCode,(!domainData ? data.customerByRegCode.domain : domainData))} >{translate.t("Pay")}</button>
                                                    <p className="text-center link-back"><a href="" onClick={(e) => {e.preventDefault();setCreditCardPayment(false);}}>{translate.t("BackToPersonalDetails")}</a></p>
                                                
                                                    {errorMessage != "" &&
                                                        <div className="alert alert-danger mt-3 text-center">{errorMessage}</div>
                                                    } 

                                                </div>

                                            </>
                                        )
                                    
                                    )
                            
                                )
  
                               
                            :
                                <Loading />
                            )
                        :
                            <div className="alert alert-danger text-center">{wholeError}</div>                 
                        } 
                    </div>
                </div>
            </Modal>
        </>


    )

}

export default withApollo(ReNewButton);