import React,{ Component } from 'react';
import {useQuery,useSubscription} from 'react-apollo';
import { GET_CUSTOMER_DETAILS } from '../Modules/Customer/Queries/customer';
import {UPDATE_PAYU_PAYMENT_SUBSCRIPTION} from '../Modules/PayU/Queries/payu';
import ReNewButton from '../Modules/PayU/Components/ReNewButton';
import moment from 'moment';
import translate from '../Modules/Translations/index';

const NoMembership = () => {

    const {data,loading,error,refetch:affDataRefetch} = useQuery(GET_CUSTOMER_DETAILS,{
        fetchPolicy: 'network-only',
    });

    const { data: subscibeData, loading:subscibeLoading } = useSubscription(UPDATE_PAYU_PAYMENT_SUBSCRIPTION,{
        onSubscriptionData(d){

            if(d.subscriptionData.data.updatePayUPayment && data && data.customerContext && d.subscriptionData.data.updatePayUPayment.customerID == data.customerContext.customerID){
                affDataRefetch();
            }
        }
    });
  	
    return (
       <>
        {!loading && data && ((moment(data.customerContext.membershipTo).hours(23).minutes(59).seconds(59) < moment() && data.customerContext.rules.showMembershipButton)) ?
            <div id="no-membership">


                {data.customerContext.lastCustomerPayment && data.customerContext.lastCustomerPayment.status == "PENDING" ?

                    <div className="alert alert-warning text-center">{translate.t("PaymentIsPending")}</div>

                :
                    (data.customerContext.lastCustomerPayment && data.customerContext.lastCustomerPayment.status == "CANCELED" ?

                        <>
                            <div className="alert alert-danger text-center">{translate.t("PaymentIsCanceled")}</div>
                            <p><ReNewButton regCode={data.customerContext.regCode} domain={data.customerContext.domain} text={translate.t("JoinUsToHaveFullAccess")} /></p>
                        </>
                        :
                        <ReNewButton regCode={data.customerContext.regCode} domain={data.customerContext.domain} text={translate.t("JoinUsToHaveFullAccess")} />
                    )
                }			       
               
               
                        
            </div>
        :null}
        </>
   
    );
  
}

export default NoMembership;
