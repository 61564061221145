/*
    Hlavní horní lišta, ktaré má v sobě i levé mennu
*/
import React,{Fragment,useState,useEffect, useRef } from 'react';
import { NavLink } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import logo from '../Public/Images/logo.svg';
import email from '../Public/Images/Icons/email_24.webp';
import giftCard from '../Public/Images/Icons/gift_card.webp';
import giftCardA from '../Public/Images/AnimatedIcons/gift_card.webp';
import diary from '../Public/Images/Icons/diary.webp';
import diaryA from '../Public/Images/AnimatedIcons/diary.webp';
import materials from '../Public/Images/Icons/materials.webp';
import materialsA from '../Public/Images/AnimatedIcons/materials.webp';
import calendar from '../Public/Images/Icons/calendar.webp';
import calendarA from '../Public/Images/AnimatedIcons/calendar.webp';
import stars from '../Public/Images/Icons/stars.webp';
import starsA from '../Public/Images/AnimatedIcons/stars.webp';
import myVs from '../Public/Images/Icons/myVs.webp';
import myVsA from '../Public/Images/AnimatedIcons/myVs.webp';
import academy from '../Public/Images/Icons/academy.webp';
import academyA from '../Public/Images/AnimatedIcons/academy.webp';
import aula from '../Public/Images/Icons/aula.webp';
import aulaA from '../Public/Images/AnimatedIcons/aula.webp';
import messages from '../Public/Images/Icons/messages.webp';
import messagesA from '../Public/Images/AnimatedIcons/messages.webp';
import intensively from '../Public/Images/Icons/intensively.webp';
import intensivelyA from '../Public/Images/AnimatedIcons/intensively.webp';
import settings from '../Public/Images/Icons/settings.webp';
import settingsA from '../Public/Images/AnimatedIcons/settings.webp';
import sun from '../Public/Images/Icons/sun.webp';
import moon from '../Public/Images/Icons/moon.webp';
import menu from '../Public/Images/Icons/menu.webp';
import door from '../Public/Images/Icons/logout.webp';
import close from '../Public/Images/Icons/close.webp';
import member from '../Public/Images/member.svg';
import { useQuery,ApolloConsumer,withApollo,useSubscription,useMutation} from 'react-apollo';
import {GET_HEADER_DATA,UPDATE_THEME} from '../GlobalQueries/globalQueries';
import Loading from '../GlobalComponents/Loading';
import ModalSupport from '../GlobalComponents/ModalSupport';
import translate from '../Modules/Translations/index';
import moment from 'moment';
import {UPDATE_PAYU_PAYMENT_SUBSCRIPTION} from '../Modules/PayU/Queries/payu';
import {UPDATE_CUSTOMER_ELITE_WINNER_MONTH,GET_EVS_MONTH_WINNER} from '../Modules/Customer/Queries/customer';
import {SERVER_URL} from '../Config/index';
import user from '../Public/Images/Icons/user_small.webp';
import ReNewButton from '../Modules/PayU/Components/ReNewButton';
import Modal from 'react-modal';
import ProfileAvatar from '../Modules/Customer/Components/ProfileAvatar';
import ScrollArea from 'react-scrollbar';
import Helper from '../Library/helper';
import { UseTheme } from '../GlobalComponents/Theme';

const Header = (props) => {

	var scrollRef = useRef();

	var lastMonth = parseInt(moment().subtract(1,"month").format("M"));
	var lastYear = parseInt(moment().subtract(1,"month").format("YYYY"));

	const [cl,setCl] = useState("");
	const [showMem,setShowMembership] = useState(false);
	const [winners,setWinner] = useState(false);
	const [showEWinner,setShowEvsWinner] = useState(false);
	const [showSupp,setShowSupport] = useState(false);
	const [showSubmenu,setShowSubmenu] = useState([
		{menuID:1,show:false},
		{menuID:2,show:false},
		{menuID:3,show:false},
		{menuID:4,show:false},
		{menuID:5,show:false},
		{menuID:6,show:false},
		{menuID:7,show:false},
		{menuID:8,show:false},
		{menuID:9,show:false},
		{menuID:10,show:false}
	]);

	const {theme, SetTheme} = UseTheme();

	const {data,loading,error,refetch:affDataRefetch} = useQuery(GET_HEADER_DATA,{
        fetchPolicy: 'network-only',
    });

	const [updateCustomerEliteWinnerMonth] = useMutation(UPDATE_CUSTOMER_ELITE_WINNER_MONTH);
	const [updateTheme] = useMutation(UPDATE_THEME);


	const { data: subscibeData, loading:subscibeLoading } = useSubscription(UPDATE_PAYU_PAYMENT_SUBSCRIPTION,{
        onSubscriptionData(d){

            if(d.subscriptionData.data.updatePayUPayment && data && data.customerContext && d.subscriptionData.data.updatePayUPayment.customerID == data.customerContext.customerID){
                
				if(data.customerContext.hasCompletedQuestionnaire == 0){
					props.history.push("/questionnaire");
				}else{
					affDataRefetch();
				}
            }
        }
	});

	useEffect(()=>{

		if(data && data.customerContext.deleted == 1){
			logout(null,props.client);
		}

		async function getWinner(){

			var data = await props.client.query({ 
				query: GET_EVS_MONTH_WINNER,
				errorPolicy:"all",
				variables:{
					month: lastMonth,
					offset:0,
					limit:10000000,
					year:lastYear
				}, 
				fetchPolicy: 'network-only'
			});
	
			if(data.data){

				var topVSCount = Math.round(data.data.allEliteVsCount / 100 * 6);
				setWinner({
					allEliteVs: data.data.allEliteVs,
					topVSCount
				});
			}
		}

		var month  = moment().format("M");
		var day    = moment().format("D");

		if(data && data.customerContext.showEliteVsWinnerMonth != month && (day != 1 || (day == 1 && moment() > moment().hours(0).minutes(30).seconds(0)))){

			if(data.customerContext.showEliteVsWinnerMonth != 0 || data.customerContext.email == "teri@laskyplnysvet.cz"){

				if(data.customerContext.rules.showEliteVS && data.customerContext.membershipTo != null){
					setShowEvsWinner(true);
					getWinner();
				}
			}
			
			updateCustomerEliteWinnerMonth({
				variables:{
					month: parseInt(month)
				}
			})
		}

		const theme = localStorage.getItem("theme");
		if(data)
		{
			if(data.customerContext.theme != theme)
			{
				SetTheme(data.customerContext.theme);
			}
			else
			{
				SetTheme(theme);
			}
		}

	},[data]);

	useEffect(() => {

		const th = localStorage.getItem("theme");
		if(theme && th != theme)
		{
			localStorage.setItem("theme", theme);
			SetTheme(theme);

			updateTheme({
				variables:{
					theme
				}
			})
		}
	},[theme])

	useEffect(() => {

		if(showSubmenu[4].show)
		{
			setTimeout(() => {
				scrollRef.scrollArea.scrollBottom();
			},1)
		}

	},showSubmenu)
	
		  
	const showSideBar = (e) => {
		e.preventDefault();
		if(cl != ""){
			setCl("");
		}else{
			setCl("open");
		}
	}
	const hideSideBar = (e) => {
		setCl("");
	}
	  
	const logout = (e,client) => {	  

		if(e)e.preventDefault();
		client.resetStore();

		localStorage.removeItem("token");
		localStorage.removeItem("loggedUser");
		localStorage.removeItem("membershipTo");

		window.location = "/";

	}
	  
	const toogleSubmenu = (e,menu,prevent) => {
		  		  
		if(prevent)e.preventDefault();
			  
		var arr = showSubmenu.map(function(item,index){
			if(index !== menu)return {...item,show:false};
			else return {...item,show:!item.show};
		});	
		  
		if(prevent)setShowSubmenu(arr);
		else{
			setShowSubmenu(arr);
			setCl("");
		}
	}
	  
	const handleClickOutside = evt => {
		hideSideBar();
	};

	const showMembership = () => {
		setShowMembership(!showMem);
	}


	const showSupport = () => {
		setShowSupport(true);	
	}
	const closeSupport = () => {
		setShowSupport(false);	
	}

	const getMessagesString = (count) => {

		var messagesString = "";

		switch(count){
			case 1:  messagesString = translate.t("new-zprava");break;
			case 2:  messagesString = translate.t("new-zpravy");break;
			case 3:  messagesString = translate.t("new-zpravy");break;
			case 4:  messagesString = translate.t("new-zpravy");break;
			case 5:  messagesString = translate.t("new-zprav");break;
			default: messagesString = translate.t("new-zprav");break;
		}

		return messagesString;
	}

	const getStringMonth = (month) => {

		var str = "";

		switch(month){
			case 1 : str = translate.t("Leden") ; break;
			case 2 : str = translate.t("Únor") ; break;
			case 3 : str = translate.t("Březen") ; break;
			case 4 : str = translate.t("Duben") ; break;
			case 5 : str = translate.t("Květen") ; break;
			case 6 : str = translate.t("Červen") ; break;
			case 7 : str = translate.t("Červenec") ; break;
			case 8 : str = translate.t("Srpen") ; break;
			case 9 : str = translate.t("Září") ; break;
			case 10 : str = translate.t("Říjen") ; break;
			case 11 : str = translate.t("Listopad") ; break;
			case 12 : str = translate.t("Prosinec") ; break;
		}

		return str;

	}
	  
	return (
		
		<div id="header">
			<ul className="d-flex m-0 p-0 main">

				{loading ?
					null
				:
					<>
						<li className="menu-icon"><button tabIndex="0" onClick={(e) => showSideBar(e)} ><img src={menu} /></button></li>
						<li className="logo" onClick={() => hideSideBar()}><img src={logo} /></li>
						{data.customerContext.membershipTo && data.customerContext.membershipTo != "0000-00-00 00:00:00" ? 
							<>
								<li className="membership ml-auto" onClick={() => hideSideBar()}><strong>{translate.t('MembershipValidTo')}: <span>{moment(data.customerContext.membershipTo).format("DD.MM.YYYY")}</span></strong></li>
								<li className="messages">
									<NavLink onClick={() => hideSideBar()} to="/vs-list" className="d-flex align-items-center">
										<div><img src={email} /></div>

										{data.customerContext.totalNotReadChatMessages > 0 || data.customerContext.notJoinedGroupCount > 0 ?
											<div className="badge badge-danger">
												{(data.customerContext.totalNotReadChatMessages > 0 ? data.customerContext.totalNotReadChatMessages : "Sk.")}
											</div>
										:null}

									</NavLink>
									
								</li>
							</>
							:
							<div className="ml-auto">
								<ReNewButton firstPayment = {true} regCode={data.customerContext.regCode} text={translate.t("ActivateMembership")} />
							</div>
						}
														
						<li className='theme'>
							{theme == 1 ?
								<button onClick={() => SetTheme(2)}><img src={moon} /></button>
							:
								<button onClick={() => SetTheme(1)}><img src={sun} /></button>
							}
						</li>
						<li className="user">
							<NavLink onClick={() => hideSideBar()} to="/settings/personal-details" className="d-flex align-items-center">
								<div className="header-avatar"><img src={(data.customerContext.photo != "" ? SERVER_URL + "/Public/Images/Avatar/stredni_" + data.customerContext.photo :user)} /></div>
							</NavLink>
						</li>
						<li className="">
							<ApolloConsumer>
								{client => (
									<button onClick={(e) => logout(e,client)}><img src={door} /></button>
								)}
									
							</ApolloConsumer>
						</li>
					</>
				}
			</ul>
			<div className={"sideBar " + cl}>

				<img alt="Astrální akademie" className="logo" src={logo} />

				{data ?
					(data.customerContext && moment(data.customerContext.membershipTo).hours(23).minutes(59).seconds(59) > moment() ?
						(data.customerContext.lastCompletedCustomerPayment && data.customerContext.lastCompletedCustomerPayment.amount == 0 && data.customerContext.autoPayments == 1 &&  data.customerContext.lastCompletedCustomerPayment.orderID.includes("sub_nocreditcard") ?

							<ReNewButton waitToTheEndOfFreeTrial={true} withBorder={true} onlyAnnualMembership = {false} onlyDiscountPrice = {false} regCode={data.customerContext.regCode} domain={data.customerContext.domain} text={translate.t("BuyMembership")} />

						:
							<ReNewButton withBorder={true} onlyAnnualMembership = {true} onlyDiscountPrice = {true} regCode={data.customerContext.regCode} domain={data.customerContext.domain} text={translate.t("BuyAnnualPreferentialMembership")} />
						)
					:
						<ReNewButton withBorder={true} onlyAnnualMembership = {false} onlyDiscountPrice = {false} regCode={data.customerContext.regCode} domain={data.customerContext.domain} text={translate.t("BuyMembership")} />
					)
				:null}

				{loading ?
					<Loading />
				:
					<ScrollArea
						ref = {(ref) => {scrollRef = ref}}
						smoothScrolling={true}
					>
						<ul>
							
							{false && data.customerContext.rules.showPageAfterReg && (data.customerContext.membershipTo == null || moment(data.customerContext.membershipTo) <= moment()) ?
								<li><NavLink onClick={(e) => toogleSubmenu(e,0)} activeClassName="active" to={"/welcome-page"}>{translate.t("Welcome")}</NavLink></li>
							:null}

							{data.customerContext.rules.showDaily ?
								<li className="d-flex align-items-center">
									<NavLink onClick={(e) => toogleSubmenu(e,6)} activeClassName="active" to={"/daily"}>
										<img className="static" src={diary} /> 
										<img className="animated" src={diaryA} />
										{translate.t("Daily")}
									</NavLink>
								</li>
							:null}
														
							{data.customerContext.rules.showMaterial ?
								<li>
									<NavLink onClick={(e) => toogleSubmenu(e,5)} activeClassName="active" to={"/materials"}>
										<img className="static" src={materials} /> 
										<img className="animated" src={materialsA} />
										{translate.t("Materials")}
									</NavLink>
								</li>
							:null}

							{data.customerContext.rules.showMyVS ?
								<li className="d-flex align-items-center">
									<NavLink onClick={(e) => toogleSubmenu(e,7)} activeClassName="active" to={"/my-rullers-of-the-force"}>
										<img className="static" src={myVs} /> 
										<img className="animated" src={myVsA} />
										{translate.t("MyVS")}
									</NavLink> 
									<div className={(data.customerContext.totalNotReadPostOfMyVS == 0 ? "badge badge-success" : "badge badge-danger")}>
										{data.customerContext.totalNotReadPostOfMyVS}
									</div>
								</li>
							:null}

							{data.customerContext.rules.showEventsCalendar ?
								<li className="d-flex align-items-center">
									<NavLink onClick={(e) => toogleSubmenu(e,8)} activeClassName="active" to={"/events"}>
										<img className="static" src={calendar} /> 
										<img className="animated" src={calendarA} />
										{translate.t("EventsCalendar")}
									</NavLink>
								</li>
							:null}

							{data.customerContext.rules.showEliteVS  && data.customerContext.membershipTo != null ?
								<li className="d-flex align-items-center">
									<NavLink onClick={(e) => toogleSubmenu(e,8)} activeClassName="active" to={"/elite"}>	
										<img className="static" src={stars} /> 
										<img className="animated" src={starsA} />
										{translate.t("EliteVS")}
									</NavLink>
								</li>
							:null}

							{(data.customerContext.rules.showRooms || data.customerContext.rules.showRoomsWithNoMembership) ?
								<Fragment>
									<li>
										<NavLink onClick={(e) => toogleSubmenu(e,2,true)} to={"/room"}>
											<img className="static" src={academy} /> 
											<img className="animated" src={academyA} />
											{translate.t("VSHall")}
										</NavLink>
										<ul className={(showSubmenu[2].show) ? '' : 'hidden' }>
											<li className="activated"><NavLink onClick={() => handleClickOutside()} exact activeClassName="active" to="/room/1g5fd6g41df6g541dg48641sd5456446">Místnost 1</NavLink></li> 
											<li className="activated"><NavLink onClick={() => handleClickOutside()} exact activeClassName="active" to="/room/ac8beae3ef071ab806a50caaaf248285">Místnost 2</NavLink></li> 
											<li className="activated"><NavLink onClick={() => handleClickOutside()} exact activeClassName="active" to="/room/25666d844c2c6c6dfacac8dda39c0021">Místnost 3</NavLink></li> 
											<li className="activated last"><NavLink onClick={() => handleClickOutside()} exact activeClassName="active" to="/room/57d830344e1e862b8195b72e22a652a0">Místnost 4</NavLink></li> 
										</ul>
									</li>
									<li>
										<NavLink onClick={(e) => toogleSubmenu(e,3)} activeClassName="active" to={"/aula/bbf3d1e8866defe9b5b63c7fd2de6e88"}>
											<img className="static" src={aula} /> 
											<img className="animated" src={aulaA} />
											{translate.t("HallOfVS")}
										</NavLink>
									</li>
								</Fragment>
							:null}

							{data.customerContext.rules.showVsList ?
								<li className="d-flex align-items-center">
									<NavLink className="no-padding-right" onClick={(e) => toogleSubmenu(e,0)} activeClassName="active" to={"/vs-list"}>
										<img className="static" src={messages} /> 
										<img className="animated" src={messagesA} />
										{translate.t("MESSAGES")}
									</NavLink> 
									{data.customerContext.totalNotReadChatMessages > 0 || data.customerContext.notJoinedGroupCount > 0 ? 
										<div className="badge badge-danger">
											{data.customerContext.totalNotReadChatMessages > 0 ? data.customerContext.totalNotReadChatMessages + " " + getMessagesString(data.customerContext.totalNotReadChatMessages) : "Sk."}
										</div>
									:null}
								</li>
							:null}

							{data.customerContext.rules.showIntensively ?
								<li className="d-flex align-items-center">
									<NavLink onClick={(e) => toogleSubmenu(e,6)} activeClassName="active" to={"/intensively"}>
										<img className="static" src={intensively} /> 
										<img className="animated" src={intensivelyA} />
										{translate.t("Intensively")}
									</NavLink>
									{moment("2023-02-20 23:59:59") > moment() ?
										<div className="badge badge-danger ml-auto mr-3">{translate.t("New")}</div>
									:null}
								</li>
							:null}

							{data.customerContext.rules.showAffiliate ?
								<li>
									<NavLink onClick={(e) => toogleSubmenu(e,1)} activeClassName="active" to={"/affiliate"}> 
										<img className="static" src={giftCard} />
										<img className="animated" src={giftCardA} /> 
										{translate.t("LovelyGift")}
									</NavLink>
								</li>
							:null}

							<li>
								<div className="d-flex align-items-center">
									<NavLink onClick={(e) => toogleSubmenu(e,4,true)} to={"/settings"}>
										<img className="static" src={settings} /> 
										<img className="animated" src={settingsA} />
										{translate.t("Settings")}
									</NavLink>
								</div>
								<ul className={(showSubmenu[4].show) ? '' : 'hidden' }>
									<li className="activated d-flex align-items-center"><NavLink onClick={() => handleClickOutside()} exact activeClassName="active" to="/settings/personal-details">{translate.t("PersonalDetails")}</NavLink></li>
									{data.customerContext.lastCompletedCustomerPayment && data.customerContext.lastCompletedCustomerPayment.amount == 0 && data.customerContext.autoPayments == 1 &&  data.customerContext.lastCompletedCustomerPayment.orderID.includes("sub") ?
										<li className="activated d-flex align-items-center"><NavLink onClick={() => handleClickOutside()} exact activeClassName="active" to="/settings/trial-period">{translate.t("TrialPeriod")}</NavLink></li>
									:null}
									<li className="activated d-flex align-items-center"><NavLink onClick={() => handleClickOutside()} exact activeClassName="active" to="/settings/privacy">{translate.t("Privacy")}</NavLink></li>
									{data.customerContext.rules.showSettingsRooms ? 
										<li className="activated"><NavLink onClick={() => handleClickOutside()} exact activeClassName="active" to="/settings/rooms">{translate.t("Rooms")}</NavLink></li> 
									:null}
									<li className="activated"><NavLink onClick={() => handleClickOutside()} exact activeClassName="active" to="/settings/notifications">{translate.t("Notifications")}</NavLink></li>
									<li className="activated last"><NavLink onClick={() => handleClickOutside()} exact activeClassName="active" to="/settings/change-password">{translate.t("ChangePassword")}</NavLink></li> 
									
								</ul>
							</li>

						</ul>
	
						{/************************   zvýraznení NEW ***************/ false ?
							<div className="badge badge-danger ml-auto mr-3">{translate.t("New")}</div>	
						:null}

						{false && data.customerContext.rules.showPreparing ?
						<Fragment>
							<div>
								<h2>{translate.t('Preparing')}</h2>
							</div>

							<ul>
								<li><span>Elitní Vládci Síly</span></li>
							</ul>
						</Fragment>
						:null}
					</ScrollArea>
				}
				<div className="dot tl"></div>
				<div className="dot tr"></div>
			</div>
			{cl == "open" ?
				<div className="side-bar-overlay" onClick={() => setCl("")}></div>
				:
				null
			}
			
			{showSupp && 
				<ModalSupport client={props.client} callback={closeSupport} />
			}

			<Modal
				className="Modal__Bootstrap modal-dialog modal-lg transparent"
				closeTimeoutMS={150}
				isOpen={(showEWinner)}
				onRequestClose={() => setShowEvsWinner(false)}
			>
				<div className="modal-content">
					<div className="modal-header d-flex align-items-center">
						{translate.t("EliteVSForMonth") + getStringMonth(lastMonth) + " " + lastYear}
						<img src={close} className="close" onClick={() => setShowEvsWinner(false)} />
					</div>
					<div className="modal-body event">

						{winners ?

							<>
								<div className="row">
									<div className="col-12">
										<div className="pyro">
											<div className="before"></div>
											<div className="after"></div>
										</div>
									</div>
									{winners.allEliteVs.map((item,index) => {

										var indexRepair = index;
										if(lastMonth != 2 || lastYear != 2022) indexRepair = index + 1;

										if(winners.topVSCount >= indexRepair){

											return(

												<div key={index} className="col-6 col-md-4">
													<ProfileAvatar customer = {item} aLink={true} isFluid={true} showPoints={true} inRow={false} withLink = {true} />
												</div>

											);

										}else{
											return("");
										}

									})}
									
								</div>

								
							</>
						
						:
							<Loading />
						}

					</div>
				</div>
			</Modal> 
		</div>
				
	);
	
  
}

export default withApollo(withRouter(Header));
