import React from 'react';
import io from "socket.io-client";
import {WEBSOCKET_SERVER} from '../../../Config/index';
import Notification from '../../../Library/notification';
import moment from 'moment';

class Room {

    constructor(props){
        this.props = props;
    }

    setConnection(socket,customerData,roomNumber,setRoomControllData){
        
        socket.on('disconnect', (reason) => {

            if(reason == "transport error" || reason == "ping timeout" || reason == "transport close"){

                setRoomControllData(prevValue => {

                    if(prevValue.openRoom == true && (prevValue.isModerator || !prevValue.showKnockButton)){

                        var dataToSend = {
                            roomNumber:roomNumber,
                            user:{
                                customerID:         customerData.customerID,
                                name:               customerData.name,
                                surname:            customerData.surname,
                                astralName:         customerData.astralName,
                                roomName:           customerData.roomName,
                                customerTypeID:     customerData.customerTypeID,
                                hasApprovalToRoom:  (prevValue.isModerator ? true : prevValue.hasApprovalToRoom),
                                isModerator:        prevValue.isModerator
                            }
                        }

                        socket.emit('join-room', dataToSend);

                    }else{
                        socket.emit('is-room-open', roomNumber)
                    }

                    var newValue = {...prevValue};
                    newValue.socket = socket;

                    return newValue;

                })

            }

        });

        socket.on("disconnect-user", (data) => {

            var showReloadButton = false;
            if(!data.isRoomOpen){
                showReloadButton = true;
            }
            this.disconnectVideo(data.isModerator,setRoomControllData,showReloadButton);
        });

        return socket;
    }

    isRoomOpened(socket,roomNUmber){
        socket.emit('is-room-open', roomNUmber);
    }

    receiveIsRoomOpened(socket,roomNumber,customerData,setRoomControllData){
        
        socket.on("is-room-open", (data) => {

            var isModerator;

            if(data.value == true){

                isModerator = (data.customerID && data.customerID == customerData.customerID ? true : false);

                var dataToSend = {
                    roomNumber:roomNumber,
                    user:{
                        customerID:         customerData.customerID,
                        name:               customerData.name,
                        surname:            customerData.surname,
                        astralName:         customerData.astralName,
                        roomName:           customerData.roomName,
                        customerTypeID:     customerData.customerTypeID,
                        hasApprovalToRoom:  (isModerator ? true : false),
                        isModerator:        isModerator
                    }
                }
                if(isModerator || data.customerID)socket.emit('join-room', dataToSend);

            }else{
                isModerator = false;
            }

            setRoomControllData(prevRoomControllData => {

                if(prevRoomControllData.openRoom == true && data.value == false){

                    this.disconnectVideo(isModerator,setRoomControllData,null);
                    return prevRoomControllData;

                }else{
    
                    var newValue = {...prevRoomControllData};
                    newValue.isModerator     = isModerator;
                    newValue.openRoom        = data.value;
                    newValue.firstInitSocket = true;
                    newValue.showKnockButton = (data.value == true ? (data.customerID ? false : true) : false);
    
                    return newValue;
                }
            })
        });
    }

    doKnock(roomNumber,customerData,setRoomControllData){
        setRoomControllData((prevData) => {

            var dataToSend = {
                roomNumber:roomNumber,
                user:{
                    customerID:         customerData.customerID,
                    name:               customerData.name,
                    surname:            customerData.surname,
                    astralName:         customerData.astralName,
                    roomName:           customerData.roomName,
                    customerTypeID:     customerData.customerTypeID,
                    hasApprovalToRoom:  false,
                    isModerator:        false
                }
            }
            
            prevData.socket.emit('join-room', dataToSend);

            var newValue = {...prevData};
            newValue.showKnockButton = false;

            return newValue;
        })
    }

    receiveAllUsers(socket,setAllUsers,setAllWaitingUsers){
        socket.on("all-room-users", (data) => {
            setAllUsers(data.users);
            setAllWaitingUsers(data.waitingUsers);
        });
        
    }

    setOpenRoom(setRoomControllData,roomNumber,value,customerID){

        setRoomControllData((prevData) => {
            prevData.socket.emit('open-room', {roomNumber,value,customerID});
            return prevData;
        })
    }

    approveEnter(setRoomControllData,customerID){

        setRoomControllData((prevData) => {
            prevData.socket.emit('approve-enter', {customerID});
            return prevData;
        })
    }

    removeUser(setRoomControllData,customerID){

        setRoomControllData((prevData) => {
            prevData.socket.emit('remove-user', {customerID});
            return prevData;
        })
        
    }

    receiveApproveEnter(socket,callBack){
       
        socket.on("approve-enter", (data) => {
            if(data && callBack)callBack(true);
        });
    }

    receiveNewApprovalNotification(socket){
       
        socket.on("new-approval", (data) => {

            let audio = new Audio("/Sound/door-knock-sound-effect.mp3");
            audio.play();
            
            let notify = new Notification();
            notify.setNotification(null,(data.astralName != "" ? data.astralName : (data.roomName != "" ? data.roomName : data.name + " " + data.surname)) + " žádá o přijetí",true,false,this.props.client);
            
        });
    }

    offEvents(socket){
        socket.removeAllListeners("new-approval");
        socket.removeAllListeners("all-room-users");
        socket.removeAllListeners("is-room-open");
        socket.removeAllListeners("disconnect-user");
        socket.removeAllListeners("disconnect");
        socket.removeAllListeners("approve-enter");
    }

    disconnectVideo(isModerator,setRoomControllData,showReloadButton){
                        
        setRoomControllData((prevValue) => {

            clearInterval(prevValue.timeInterval);

            var newValue = {...prevValue};

            clearInterval(newValue.timeInterval);

            if(newValue.api){
                newValue.api.executeCommand('hangup');
                newValue.api.dispose();
            }

            newValue.socket.emit("disconnect-user");

            this.offEvents(newValue.socket);

            newValue.api                = null;
            newValue.socket             = null;
            newValue.openRoom           = false;
            newValue.isModerator        = false;
            newValue.firstInitSocket    = false;
            newValue.hasApprovalToRoom  = false;
            newValue.showReloadButton   = false;
            newValue.showKnockButton    = true;
            newValue.showReloadButton   = (showReloadButton ? true : false);
            newValue.time               = 0;
            newValue.timeInterval       = null;
            
            return newValue;

        })
 
    }

    getRoomName(hash){

        var roomName = "";

        switch(hash){
            case "1g5fd6g41df6g541dg48641sd5456446": 
                roomName   = "Místnost 1";
                break;
            case "ac8beae3ef071ab806a50caaaf248285": 
                roomName   = "Místnost 2";
                break;
            case "25666d844c2c6c6dfacac8dda39c0021": 
                roomName   = "Místnost 3";
                break;
            case "57d830344e1e862b8195b72e22a652a0": 
                roomName   = "Místnost 4";
                break;
            case "bbf3d1e8866defe9b5b63c7fd2de6e88":
                roomName   = "Aula Astrální akademie";
                break;
            default: 
                roomName = "";
        } 

        return roomName;

    }

}

export default Room
