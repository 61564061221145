import {WEBSOCKET_SERVER} from '../../../Config/index';
import io from "socket.io-client";
import { useState,createContext,useContext } from 'react';

const socketContext = createContext();

export const SocketProvider = ({children}) => {

    const socket = UseProviderSocket();

    return(
        <socketContext.Provider value={socket}>
            {children}
        </socketContext.Provider>
    )
}

export const UseSocket = () => {
    return useContext(socketContext);
}

const UseProviderSocket = () => {

    const [receiveChatMessage, SetReceiveChatMessage] = useState(null);
    const [receiveChatMessageInVSlist, SetReceiveChatMessageInVSlist] = useState(null);
    const [receiveChatMessageInChat, SetReceiveChatMessageInChat] = useState(null);
    const [allRooms, SetAllRooms] = useState(null);
    const [socket, SetSocket] = useState(null);

    const SetConnection = () => {

        var s = io.connect(WEBSOCKET_SERVER);

        s.on("receive-chat-message",(data) => {
            SetReceiveChatMessage(data);
        });

        s.on("receive-chat-message-in-vs-list",(data) => {
            SetReceiveChatMessageInVSlist(data);
        });

        s.on("receive-chat-message-in-chat",(data) => {
            SetReceiveChatMessageInChat(data);
            setTimeout(() => {
                SetReceiveChatMessageInChat(null);
            },100)
        });
        s.on("get-all-rooms",(data) => {
            SetAllRooms(data);
        });

        SetSocket(s);
    }

    const SendChatMessage = (data) => {
        socket.emit('send-chat-message', data)
    }

    const GetAllRooms = () => {
        socket.emit('get-all-rooms');
    }

    const CloseRoom = (roomNumber) => {
        socket.emit("close-room",roomNumber);
    }
    
    return({
        receiveChatMessage,
        receiveChatMessageInVSlist,
        receiveChatMessageInChat,
        allRooms,
        socket,
        SetConnection,
        SendChatMessage,
        GetAllRooms,
        CloseRoom
    })

}
