import gql from 'graphql-tag';

export const UPDATE_CUSTOMER_MODAL_DATA = gql`
    mutation UpdateCustomerModalData(
        $customerID:ID,
        $selectedMaterials:[ID],
        $description:String
    ){
        updateCustomerModalData(
            customerID:          $customerID,
            selectedMaterials:   $selectedMaterials
            description:         $description
        )
    }
`;

export const UPDATE_CUSTOMER_WELCOME_PAGE_STEP = gql`
    mutation UpdateCustomerWelcomePageStep(
        $customerID:ID,
        $step:Int
    ){
        updateCustomerWelcomePageStep(
            customerID: $customerID,
            step:       $step
        )
    }
`;

export const GET_CUSTOMER_DETAILS = gql`
    query GetCustomerDetails {
        customerContext{
            customerID
            customerStripeID
            questionnaireID
            courseID
            bankAccount
            email
            astralName
            autoPayments
            roomName
            name
            surname
            street
            city
            zip
            domainID
            regCode
            membershipTo
            membershipDuration
            customerTypeID
            tel
            countryID
            myMotivation
            introduction
            actualJob
            idealJob
            iAmGoodAt
            aboutMe
            photo
            welcomePageStep
            groups{
                acceptInvitation
                groupID
            }
            domain{
                domainName
                currencyTitle
                posID
                lang
            }
            lastCustomerPayment{
                status
            }
            lastCompletedCustomerPayment{
				amount
				orderID
			}
            customerLastMaterial{
                materialID
                longName
                description
            }
            materials{
                materialID
            }
            rules{
                showForum
                showAffiliate
                showDaily
                showMaterial
                showMyVS
                showRooms
                showRoomsWithNoMembership
                showPreparing
                showMembershipButton
                showEventsCalendar
                showPageAfterReg
                showVsList
                showSettingsRooms
                showEliteVS
                showIntensively
            }
            customerPrivacy{
                customerID
                privacyID
            }
            course{
                successMessage
            }
        }
        
    }
`;

export const GET_VS_LIST_DATA = gql`
    query GetVSListData($offset:Int,$limit:Int,$text:String){
        allCustomersWithActiveMembership(offset:$offset,limit:$limit,text:$text){
            customerID
            customerTypeID
            regCode
            astralName
            name
            introduction
            photo
            unreadChatMessagesCount
            customerLastMaterial{
                longName
            }
        }  
        customersWithActiveMembershipCount(text:$text)
        allCustomerGroups{
            groupCustomerID
            customerID
            groupID
            creator
            acceptInvitation
            notReadedMessages
            customer{
                name
                astralName
            }
            group{
                name
            }
        }
    }
`;

export const GET_All_ELITE_VS = gql`
query GetAllEliteVs($offset:Int,$limit:Int,$month:Int,$year:Int,$lang:String){
    allEliteVs(offset:$offset,limit:$limit,month:$month,year:$year){
        customerID
        customerTypeID
        astralName
        name
        introduction
        photo
        totalEvsPoint
        regCode
        eliteVSwinnerCount
    }
    customerContext{
        membershipTo
    }
    allEliteVsCount(month:$month,year:$year)
    evsData(lang:$lang){
        mainText
        description
    }
    evsMonthdataByMonthYear(month:$month,year:$year,lang:$lang){
        evsMonthDataID
        text
        images{
            photo
        }
    }
}
`;


export const GET_EVS_MONTH_WINNER = gql`
    query GetEvsMonthWinner ($offset:Int,$limit:Int,$month:Int,$year:Int){
        allEliteVs(offset:$offset,limit:$limit,month:$month,year:$year){
            customerID
            customerTypeID
            astralName
            name
            introduction
            photo
            totalEvsPoint
            regCode
            eliteVSwinnerCount
        }
        allEliteVsCount(month:$month,year:$year)     
    }
`;

export const GET_CUSTOMER_MODAL_DATA = gql`
    query GetCustomerModalData($customerID:ID) {
        customerContext{
            customerID
        }
        oneCustomer(customerID:$customerID){
            customerID
            questionnaireID
            bankAccount
            email
            name
            surname
            astralName
            roomName
            street
            city
            zip
            tel
            domainID
            regCode
            membershipTo
            customerTypeID
            materialChangeDate
            myMotivation
            actualJob
            idealJob
            iAmGoodAt
            aboutMe
            photo
            introduction
            description
            customerLastMaterial{
                materialID
                longName
                description
            }
            materials{
                materialID
            }
            customerPrivacy{
                privacyID
            }
        }
        
    }
`;

export const GET_CUSTOMER_PRIVACY = gql`
    query GetCustomerPrivacy{   
        customerPrivacy{
            customerID
            privacyID
        }
    }
`;

export const UPDATE_CUSTOMER_PRIVACY = gql`
    mutation UpdateCustomerPrivacy(
        $privacyID:[ID]
    ){
        updateCustomerPrivacy(
            privacyID:   $privacyID
        )
    }
`;

export const UPDATE_CUSTOMER_DETAILS = gql`
    mutation updateCustomerDetials($customerID:ID,$photo:Upload,$name:String,$surname:String,$astralName:String,$roomName:String,$street:String,$city:String,$zip:String,$bankAccount:String,$myMotivation:String,$actualJob:String,$idealJob:String,$iAmGoodAt:String,$aboutMe:String,$introduction:String) {
        updateCustomerDetials(customerID:$customerID,photo:$photo,name:$name,surname:$surname,astralName:$astralName,roomName:$roomName,street:$street,city:$city,zip:$zip,bankAccount:$bankAccount,myMotivation:$myMotivation,actualJob:$actualJob,idealJob:$idealJob,iAmGoodAt:$iAmGoodAt,aboutMe:$aboutMe,introduction:$introduction)
    }
`;

export const UPDATE_CUSTOMER_WITH_EXTENDED_MEMBERSHIP = gql`
    mutation updateDataWithExtendMembership($customerID:ID,$domainID:ID,$membershipDurationID:ID,$membershipPrice:Float,$name:String,$surname:String,$tel:String,$countryID:ID,$street:String,$city:String,$zip:String) {
        updateDataWithExtendMembership(customerID:$customerID,domainID:$domainID,membershipDurationID:$membershipDurationID,membershipPrice:$membershipPrice,name:$name,surname:$surname,tel:$tel,countryID:$countryID,street:$street,city:$city,zip:$zip)
    }
`;

export const GET_CUSTOMER_BY_REG_CODE = gql`
    query CustomerByRegCode($regCode:String!){
        customerByRegCode(regCode:$regCode){
            customerID
            customerTypeID
            membershipDuration
            name
            astralName
            surname
            email
            street
            city
            zip
            tel
            photo
            introduction
            actualJob
            idealJob
            aboutMe
            iAmGoodAt
            myMotivation
            eliteVSwinnerCount
            customerLastMaterial{
                materialID
                longName
                description
            }
            customerPrivacy{
                privacyID
            }
            materials{
                materialID
            }
        }
        
    }
`;

export const GET_CUSTOMER_MATERIAL_DATA = gql`
    query Customer($lang: String!){
        allMaterials(lang:$lang){
            materialID
            name
            parentID
            subMaterial{
                materialID
                name
                parentID
                subMaterial{
                    materialID
                    name
                    parentID
                    subMaterial{
                        materialID
                        name
                        parentID
                        subMaterial{
                            materialID
                            name
                            parentID
                            subMaterial{
                                materialID
                                name
                                parentID
                                subMaterial{
                                    materialID
                                    name
                                    parentID
                                }
                            }
                        }
                    }
                }
            }
        }
                
    }
`;

export const GET_WELCOME_PAGE_DATA = gql`
query GetWelcomePageData ($lang:String){
    welcomePage(lang:$lang){
        text
        text2
        buttonText
        videoUrl
        videoDesc
    }
    
}
`;

export const SAVE_CHAT_MESSAGE = gql`
    mutation SaveChatMessage(
        $fromCustomerID:ID
        $toCustomerID:ID
        $toGroupID:ID
        $message:String
        $image:String
        $width:Int
        $height:Int
    ){
        saveChatMessage(
            fromCustomerID: $fromCustomerID
            toCustomerID:   $toCustomerID
            toGroupID:      $toGroupID
            message:        $message
            image:          $image
            width:          $width
            height:         $height
        ){
            customerChatMessagesID,
            message
            image
            width
            height
        }
    }
`;


export const GET_ALL_CHAT_MESSAGES = gql`
    query GetAllChatMessages(
        $meID:ID,
        $vsID:ID,
        $groupID:ID,
        $limit:Int,
        $offset:Int,
        $useCount:Boolean
    ){
        getAllChatMessages(
            meID: $meID,
            vsID: $vsID,
            groupID:$groupID,
            limit: $limit,
            offset: $offset,
            useCount: $useCount
        ){
            customerChatMessagesID
            fromCustomerID
            toCustomerID
            toGroupID
            message
            image
            width
            height
            readed
            readedInGroup
            dateAdd
            fromCustomer{
                astralName
                name
                photo
            }
        }
        getAllChatMessagesCount( meID: $meID,vsID: $vsID,groupID:$groupID)
    }
`;

export const UPDATE_CHAT_MESSAGE = gql`
    mutation UpdateChatMessage($customerChatMessagesID:ID,$readedCustomerID:ID,$groupID:ID){
        updateChatMessage(customerChatMessagesID:$customerChatMessagesID,readedCustomerID:$readedCustomerID,groupID:$groupID)
    }
`;

export const UPLOAD_CHAT_IMAGES = gql`
    mutation UploadChatImages($images:[Upload]){
        uploadChatImages(images:$images){
            name
            width
            height
        }
    }
`;

export const UPDATE_NOT_READ_CHAT_MESSAGES = gql`
mutation UpdateNotReadChatMessages($meID:ID,$vsID:ID,$groupID:ID){
    updateNotReadChatMessages(meID:$meID,vsID:$vsID,groupID:$groupID)
}
`;

export const ADD_EVS_POINTS_FROM_ROOM = gql`
    mutation AddEvsPointsFromRoom{
        addEvsPointsFromRoom
    }
`;

export const UPDATE_CUSTOMER_ELITE_WINNER_MONTH = gql`
    mutation UpdateCustomerEliteWinnermonth($month:Int){
        updateCustomerEliteWinnermonth(month:$month)
    }
`;

export const GET_CUSTOMERS_EVS_POINTS = gql`
    query CustomerEVSPoints($month:Int, $year: Int,$customerID:ID){
        allCustomerEVSPoints(month:$month,year:$year,customerID:$customerID){
            customerEVSPointID
            dateAdd
            info
            points
        } 
    }
`;
export const REMOVE_MEMBERSHIP = gql`
    mutation RemoveMembership(
        $customerID:ID 
        $isTrialPeriod:Boolean
    ){
        removeMembership(
            customerID:  $customerID,
            isTrialPeriod: $isTrialPeriod
        ){
            customerStripeID
            autoPayments
        }
    }
`;

export const GET_CUSTOMER_QUESTIONNAIRE_STEPS = gql`
    query GetCompleteQuestionnaireSteps($lang:String,$customerID:ID,$questionnaireID:ID){
        oneCustomer(customerID:$customerID){
            hasCompletedQuestionnaire
        }
        getCompleteQuestionnaireSteps(lang:$lang,withDeleted:true,questionnaireID:$questionnaireID){
            questionnaireStepID
            questionTypeID
            lang
            question
            items{
                questionnaireStepItemID
                text
            }
        } 
        customerQuestionnaireSteps(customerID:$customerID,questionnaireID:$questionnaireID){
            questionnaireStepID
            text
            checkboxQuestionnaireStepItemIDs
            radioQuestionnaireStepItemID
        }

    }
`;

export const UPDATE_BANK_ACCOUNT = gql`
    mutation UpdateBankAccount(
        $bankAccount:String
    ){
        updateBankAccount(
            bankAccount:  $bankAccount
        )
    }
`;
