import React,{useState} from 'react';
import {withApollo,useQuery } from 'react-apollo';
import {GET_EVENTS} from '../Queries/event';
import Error from '../../../GlobalComponents/Error';
import Loading from '../../../GlobalComponents/Loading';
import translate from '../../Translations/index';
import moment from 'moment';
import Helper from '../../../Library/helper';
import Modal from 'react-modal';
import close from '../../../Public/Images/Icons/close.webp';

const Events = (props) => {

    var offset = 0;
    var limit = 0;
    var variables = {
        onlyFuture: true,
        offset,
        limit,
        lang: translate.locale
    }

    const [eventDetails, showEventDetails] = useState(null);

    const {data,loading,error,refetch,fetchMore} = useQuery(GET_EVENTS,{
        fetchPolicy: 'network-only',
        variables
    });

    var err = "";
    if(error){

        var helper = new Helper();
        err = helper.getApolloErrorText(error);  

    }

    return(
        <div>

            {err ?
                <Error text = {err} />
            :
                (loading ?
                    <Loading />
                :
                    <div className="whole-container">
                        <div className="calendar-events">
                            
                        
                            {data.allEvents && data.allEvents.length > 0 ?

                                <>
                                
                                    {data.allEvents.map((item,index) => (


                                        <div key={index} className={"card event"}>

                                            <div className="card-body d-flex align-items-center" onClick={() => showEventDetails(item)}>

                                                <div className="date">
                                                    {moment(item.eventDate).format("DD.MM.")}
                                                    <div className="year">{moment(item.eventDate).format("HH:mm")}</div>
                                                </div>    
                                                <div className="title">
                                                    {item.title}
                                                </div>                                            
                                                
                                            </div>

                                        </div>

                                    ))}

                                    {data.allEventsCount > data.allEvents.length ?

                                        <p className="text-center mt-5">
                                            <button 
                                                onClick={async  () => await fetchMore({
                                                    variables: {
                                                        offset:data.allEvents.length,
                                                        limit,
                                                        onlyFuture: true,
                                                        lang:translate.locale
                                                    },
                                                    updateQuery: (prev, { fetchMoreResult }) => {
                                                    
                                                    if (!fetchMoreResult) return prev.allEvents;
                                                    return Object.assign({}, prev, {
                                                        allEvents: [...prev.allEvents, ...fetchMoreResult.allEvents]
                                                    });
                                                    }
                                                })} 
                                                className="btn btn-primary">
                                                {translate.t("LoadMore")}
                                            </button>
                                        </p>
                                    
                                    :
                                    null}
                                
                                </>

                            :

                                <div className="card">

                                    <div className="card-body">

                                        <div className="alert alert-warning text-center no-margin">{translate.t("EmptyEvents")}</div>
                                        
                                    </div>

                                </div>

                            }

                            
                        </div>

                        {eventDetails ?

                            <Modal
                                className="Modal__Bootstrap modal-dialog modal-lg"
                                closeTimeoutMS={150}
                                isOpen={true}
                                onRequestClose={() => showEventDetails(null)}
                            >
                                <div className="modal-content">
                                    <div className="modal-header d-flex align-items-center">
                                        {moment(eventDetails.eventDate).format("DD.MM.YYYY HH:mm") + " - " + eventDetails.title}
                                        <img src={close} className="close" onClick={() => showEventDetails(null)} />
                                    </div>
                                    <div className="modal-body event">

                                        <div className="tiny-content" dangerouslySetInnerHTML={{__html:eventDetails.text}}></div>

                                    </div>
                                </div>
                            </Modal>
                            
                        :null}
                    </div>
                
                )
            
            }

            

        </div>
    )

}

export default withApollo(Events);