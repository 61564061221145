import React from 'react';
import {useQuery} from 'react-apollo';
import {GET_CUSTOMER_DETAILS} from '../../Customer/Queries/customer';
import Loading from '../../../GlobalComponents/Loading';
import AllCustomerDailyPosts  from './AllCustomerDailyPosts';

const Daily = (props) => {

    const {data,loading,error} = useQuery(GET_CUSTOMER_DETAILS,{
        fetchPolicy: 'network-only',
    });

    return(
        <div id="daily" className="whole-container">

            {loading ?
                <Loading />
            :

                <AllCustomerDailyPosts isVSList = {false} materials = {data.customerContext.materials} lastMaterialID = {data.customerContext.customerLastMaterial.materialID} guideID = {0} myMotivation = {data.customerContext.myMotivation} customerID = {data.customerContext.customerID} />
 
            }  

        </div>
    )

}

export default Daily;