import React,{ Fragment, useState } from 'react';
import { withApollo,useQuery } from 'react-apollo';
import {GET_All_ELITE_VS} from '../Queries/customer';
import Loading from '../../../GlobalComponents/Loading';
import translate from '../../Translations/index';
import moment from 'moment';
import {SERVER_URL} from '../../../Config/index';
import NoItems from '../../../GlobalComponents/NoItems';
import Modal from 'react-modal';
import Slider from "react-slick";
import Lightbox from 'react-image-lightbox';
import "slick-carousel/slick/slick.css"; 
import 'react-image-lightbox/style.css'; 
import ProfileAvatar from './ProfileAvatar';
import close from '../../../Public/Images/Icons/close.webp';


const EliteVS = (props) => {

    const settings = {
        className: "slider variable-width",
        dots: false,
        infinite: true,
        centerMode: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true
    };

    var year    = moment().format("YYYY");
    var month   = moment().format("M");
    var intYear = parseInt(year);
    var noData  = false;

    const [showMainText,setShowMainText] = useState(false);
    const [showDescription,setShowDescription] = useState(false);
    const [selectedYear,setSelectedYear] = useState(year);
    const [selectedMonth,setSelectedMonth] = useState(month);
    const [lbOpen,setLbOpen] = useState(false);
    const [lbSliderNumber,setlbSliderNumber] = useState(1);

    var years = [];
    for(let i = 2022; i <= intYear; i++){
        years.push(i);
    }

    const variables = {
        offset:0,
        limit:1000,
        month:parseInt(selectedMonth),
        year: parseInt(selectedYear),
        lang: translate.locale
    }

    const {data,loading,error,} = useQuery(GET_All_ELITE_VS,{
        variables:variables,
        fetchPolicy: 'network-only',
    });

    if(data && data.customerContext && data.customerContext.membershipTo == null){
        props.history.push("/");
    }

    var topVSCount = 0;
    var modalContent = "";
    var images = [];

    if(data){
        topVSCount = Math.round(data.allEliteVsCount / 100 * 6);

        modalContent = (data.evsData[0].mainText ? data.evsData[0].mainText : "");
        if(showDescription)modalContent = (data.evsData[0].description ? data.evsData[0].description : "");

        if(data.evsMonthdataByMonthYear && data.evsMonthdataByMonthYear.images){
            for(let val of data.evsMonthdataByMonthYear.images){
                images.push(SERVER_URL + "/Public/Images/EvsMonthData/id-" + data.evsMonthdataByMonthYear.evsMonthDataID + "/velka_" + val.photo);
            }
        }
    }

    var modalTitle = translate.t("HowToGetPoints?");
    if(showDescription)modalTitle = translate.t("WhatIsEliteVS?");

    const openLb = (e,slide) => {
        e.preventDefault();
        setlbSliderNumber(slide);
        setLbOpen(true);
    }

    return(
        
        
        <div className="whole-container">

            {loading ?

                <Loading />
            
            :
                <div>

                    <div className="card">
                        <div className="card-header flex-column flex-sm-row">
                            {translate.t("ELITEVS")}
                            
                            <div className="d-flex align-items-center ml-auto select-container">
                                <select value={selectedMonth} className="form-control thiner" onChange={(e) => setSelectedMonth(e.target.value)}>
                                    <option value={1}>{translate.t("Leden")}</option>
                                    <option value={2}>{translate.t("Únor")}</option>
                                    <option value={3}>{translate.t("Březen")}</option>
                                    <option value={4}>{translate.t("Duben")}</option>
                                    <option value={5}>{translate.t("Květen")}</option>
                                    <option value={6}>{translate.t("Červen")}</option>
                                    <option value={7}>{translate.t("Červenec")}</option>
                                    <option value={8}>{translate.t("Srpen")}</option>
                                    <option value={9}>{translate.t("Září")}</option>
                                    <option value={10}>{translate.t("Říjen")}</option>
                                    <option value={11}>{translate.t("Listopad")}</option>
                                    <option value={12}>{translate.t("Prosinec")}</option>

                                </select>
                                <select value={selectedYear} className="form-control ml-2 thiner" onChange={(e) => setSelectedYear(e.target.value)}>
                                    {years.map((item,index) => (
                                        <option value={item} key={index}>{item}</option>
                                    ))}
                                </select>
                            </div>
                            
                        </div>
                        <div className="card-body">
                           
                            <div className="row">
                                <div className="col-12 col-sm-6">
                                    <button onClick={() => setShowMainText(true)} className="btn btn-primary btn-block mb-2 mb-sm-0">
                                        {translate.t("HowToGetPoints?")}
                                    </button>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <button onClick={() => setShowDescription(true)} className="btn btn-primary btn-block">
                                        {translate.t("WhatIsEliteVS?")}
                                    </button>
                                </div>
                                
                            </div>
                                
                            
                            
                        </div>
                    </div>

                    <div className="row">

                        {data && data.allEliteVs.map((item,index) => {

                            var indexRepair = index;
                            if((selectedMonth != 1 && selectedMonth != 2) || selectedYear != 2022) indexRepair = index + 1;

                            if(index == 0 && (!item.totalEvsPoint || item.totalEvsPoint == 0)){

                                noData = true;
                                return(
                                    <div key={0} className="col-12">
                                        <NoItems text={translate.t("NoDataForThisPriod")} />
                                    </div>
                                );

                            }else if(!noData){

                                if((indexRepair) > topVSCount){

                                    return (
                                        <div key={index} className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                            <ProfileAvatar customer = {item} isFluid={true} showPoints={true} inRow={true} withLink = {true} />
                                        </div>
                                    )


                                }else{

                                    return (

                                        <Fragment key={index}>
                                        
                                            <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                <ProfileAvatar customer = {item} aLink={false} isFluid={true} showPoints={true} inRow={false} withLink = {true} />
                                            </div>

                                            {indexRepair == topVSCount ?

                                                <>

                                                    {data.evsMonthdataByMonthYear ?
                                                    

                                                    

                                                        <div className="col-12 tiny-content">

                                                            <hr className="mb-2rem" />
                                                            
                                                                                                                                    
                                                            {data.evsMonthdataByMonthYear.text && data.evsMonthdataByMonthYear.text != "" ?

                                                                <div dangerouslySetInnerHTML={{__html:data.evsMonthdataByMonthYear.text}}></div>

                                                            :null}

                                                            {data.evsMonthdataByMonthYear.images && data.evsMonthdataByMonthYear.images.length > 0 ?
                                                                <div>
                                                                    <Slider {...settings}>
                                                                        {data.evsMonthdataByMonthYear.images.map((item,index) => (
                                                                            <div key={index} className="slider-item">
                                                                                <div>
                                                                                    <a href="#" onClick={(e) => openLb(e,index)}><img src={SERVER_URL + "/Public/Images/EvsMonthData/id-" + data.evsMonthdataByMonthYear.evsMonthDataID + "/mala_"+item.photo} /></a>
                                                                                </div>
                                                                            </div>
                                                                        ))} 
                                                                    </Slider>
                                                                    {lbOpen &&
                                                                        <Lightbox
                                                                            mainSrc={images[lbSliderNumber]}
                                                                            nextSrc={images[(lbSliderNumber + 1) % images.length]}
                                                                            prevSrc={images[(lbSliderNumber + images.length - 1) % images.length]}
                                                                            onCloseRequest={() => setLbOpen(false)}
                                                                            onMovePrevRequest={() =>
                                                                                setlbSliderNumber((lbSliderNumber + images.length - 1) % images.length)
                                                                            }
                                                                            onMoveNextRequest={() =>
                                                                                setlbSliderNumber((lbSliderNumber + 1) % images.length)
                                                                            }
                                                                        />
                                                                    }
                                                                </div>

                                                            :null}

                                                            <hr className="mb-2rem mt-2rem" />

                                                                
                                                        </div>


                                                        
                                                    :
                                                        <div className="col-12">
                                                            <hr className="mb-2rem" />
                                                        </div>
                                                    }
                                                </>
                                            
                                            :null}

                                        </Fragment>
                                        
                                    )

                                }

                            }else{
                                return("");
                            }

                        })}

                    </div>

                    <Modal
                        className="Modal__Bootstrap modal-dialog modal-lg"
                        closeTimeoutMS={150}
                        isOpen={(showMainText || showDescription)}
                        onRequestClose={() => (showMainText ? setShowMainText(false) : setShowDescription(false))}
                    >
                        <div className="modal-content">
                            <div className="modal-header d-flex align-items-center">
                                {modalTitle}
                                <img src={close} className="close" onClick={() => (showMainText ? setShowMainText(false) : setShowDescription(false))} />
                            </div>
                            <div className="modal-body event">

                                <div className="tiny-content" dangerouslySetInnerHTML={{__html: modalContent}}></div>

                            </div>
                        </div>
                    </Modal>    

                </div>

                
            }
            
            
        </div>
                
                
    );

    

}

export default withApollo(EliteVS);