let graphqlServerUrl              = "https://dev-server.astralniakademie.cz/graphql";
let serverUrl                     = "https://dev-server.astralniakademie.cz";
let graphqlServerWsUrl            = "wss://dev-server.astralniakademie.cz/graphql";
let webSocketServer               = "https://dev-server.astralniakademie.cz";
//let stripeKey                     = "pk_live_51IMH01KDfXXEpGN7wpPAMWSAz01l6OZva1ln1biIKJUhhrTM68ApldKxcCgNFJDUvJ4eEytNtNGj6hmQpyufAf0j00zhdDv8LW";
let stripeKey                     = "pk_test_51IMH01KDfXXEpGN7xS5YL104GFWnF5ZtJrGp9r9zCKYr9b4Sm6EBe2SSVYsfb9PtE0HcLpJcTisO1vrcj5To8mqQ00PcG4fFVY";

if (process && process.env && process.env.NODE_ENV === "development") {
    graphqlServerUrl              = "http://localhost:8918/graphql";
    serverUrl                     = "http://localhost:8918";
    graphqlServerWsUrl            = "ws://localhost:8918/graphql";
    webSocketServer               = "http://localhost:8918";
    stripeKey                     = "pk_test_51IMH01KDfXXEpGN7xS5YL104GFWnF5ZtJrGp9r9zCKYr9b4Sm6EBe2SSVYsfb9PtE0HcLpJcTisO1vrcj5To8mqQ00PcG4fFVY";

}

export const GRAPHQL_SERVER_URL              = graphqlServerUrl;
export const SERVER_URL                      = serverUrl;
export const GRAPHQL_SERVER_WS_URL           = graphqlServerWsUrl;
export const WEBSOCKET_SERVER                = webSocketServer;
export const STRIPE_PKEY                     = stripeKey;
