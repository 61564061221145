import gql from 'graphql-tag';

export const GET_NOTIFICATIONS = gql`
	{
		notifications @client{
			text
			success
			error
		}
	}
`;

export const GET_HEADER_DATA = gql`
	query HeaderData{
		customerContext{
			customerID
			customerTypeID
			membershipTo
			name
			surname
			totalNotReadPostOfMyVS
			totalNotReadChatMessages
			notJoinedGroupCount
			photo
			domainID
			showEliteVsWinnerMonth
			eliteVSwinnerCount
			email
			regCode
			deleted
			autoPayments
			hasCompletedQuestionnaire
			theme
			lastCompletedCustomerPayment{
				amount
				orderID
			}
			domain{
				currencyTitle
			}
			rules{
                showForum
                showAffiliate
                showDaily
                showMaterial
                showMyVS
                showRooms
                showRoomsWithNoMembership
                showPreparing
                showMembershipButton
				showEventsCalendar
				showPageAfterReg
				showVsList
				showSettingsRooms
				showEliteVS
				showIntensively
            }
		}
		
	}
`;

export const SEND_QUESTION = gql`
	mutation SendQuestion($text:String){
		sendQuestion(text:$text)	
	}
`;

export const UPDATE_THEME = gql`
	mutation UpdateTheme($theme:Int){
		updateTheme(theme:$theme)	
	}
`;

export const GET_DOMAIN_DATA_BY_DOMAIN = gql`
	query DomainDataByDomain($domain:String){
		domainDataByDomain(domain:$domain){
			lang
		}	
	}
`;

export const GET_SETTINGS = gql`
	query Settings($lang:String){
		settings(lang:$lang){
			version
		}	
	}
`;