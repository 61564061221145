import translate from '../../Translations/index';
import Customer from '../../Customer/Library/customer';
import {CREATE_PAYMENT,SEND_PAYMENT_CANCELED_EMAIL} from '../Queries/payu';
import Helper from '../../../Library/helper';

class StripeClass {

    constructor(props){
        this.props = props;
    }

    formHandle(e,formData,setFormData){

        var t = e.target;
        var v = t.type === 'checkbox' ? t.checked : t.value;
        var n = t.name;

        if(n == "cvv" && v.length > 3)v = formData.cvv;
        if(n == "expMonth" && v.length > 2)v = formData.expMonth;
        if(n == "expYear" && v.length > 2)v = formData.expYear;
        if(n == "creditCard" && v.length % 5 == 4 && v.length < 19 && v.length > formData.creditCard.length)v = v + " ";
        if(n == "creditCard" && v.length > 19)v = formData.creditCard;

        setFormData({...formData,[n]:v});
    }

    continueToPayment(formData,setCreditCardPayment,setError){

        if(formData.name != ""){

            if(formData.surname != ""){

                if(formData.street != ""){

                    if(formData.city != ""){

                        if(formData.zip != ""){

                            if(formData.countryID != ""){

                                if(formData.tel != ""){

                                    if(formData.membershipDurationID != 0){

                                        setCreditCardPayment(true);
                            
                                    }else{
                                        setError(translate.t("InvalidMembership"));
                                        setTimeout(() =>setError (""),4000);
                                    }
                        
                                }else{
                                    setError(translate.t("InvalidTel"));
                                    setTimeout(() =>setError (""),4000);
                                }
                    
                            }else{
                                setError(translate.t("InvalidCountry"));
                                setTimeout(() =>setError (""),4000);
                            }
                
                        }else{
                            setError(translate.t("InvalidZip"));
                            setTimeout(() =>setError (""),4000);
                        }
            
                    }else{
                        setError(translate.t("InvalidCity"));
                        setTimeout(() =>setError (""),4000);
                    }
        
                }else{
                    setError(translate.t("InvalidStreet"));
                    setTimeout(() =>setError (""),4000);
                }
    
            }else{
                setError(translate.t("InvalidSurname"));
                setTimeout(() =>setError (""),4000);
            }

        }else{
            setError(translate.t("InvalidName"));
            setTimeout(() =>setError (""),4000);
        }
        
    }

    async makeOrder(e,setPaymentError,stripe,elements,CardElement,formData,setSuccesPayment,setLoading,setError,client,regCode,domainData){

        e.preventDefault();

        const result = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement)
        });

        if(result.error){

            setLoading(false);
            setError(result.error.message);
            setTimeout(() =>setError (""),4000);

        }else{

            setLoading(true);

            try{

                var autopayment          = false;
                var customerID           = formData.customerID;
                var membershipDurationID = formData.membershipDurationID;
                var name                 = formData.name;
                var surname              = formData.surname;
                var email                = formData.email;
                var city                 = formData.city;
                var street               = formData.street;
                var zip                  = formData.zip;
                var tel                  = formData.tel;
                var countryID            = formData.countryID;
                var domainID             = formData.domainID;

                var customer = new Customer();
                await customer.updateDataWithExtendMembership({
                    customerID,
                    membershipDurationID,
                    membershipPrice:formData.membershipPrice,
                    name,
                    surname,
                    email,
                    city,
                    street,
                    zip,
                    tel,
                    countryID,
                    domainID
                },client);

                //var token = CryptoJS.AES.encrypt(creditCard + "-" + formData.cvv + "-" + formData.expMonth + "-" + formData.expYear, 'pmS3luOapErUbnPHD493Wjfsfd97IdnelW').toString();

                var cpd = await this.createPayment(client,result.paymentMethod.id,customerID,membershipDurationID,name,surname,email,city,street,zip,autopayment,domainData.domainName,tel,0,0);                                   
                if(cpd.data.createPayment.status == "active" || cpd.data.createPayment.status == "succeeded" || cpd.data.createPayment.status == "trialing"){
                    
                    //úspěšně zapolaceno
                    setLoading(false);
                    setSuccesPayment(true);

                }else{

                    stripe.confirmCardPayment(cpd.data.createPayment.clientSecret, {
                        payment_method: result.paymentMethod.id
                    })
                    .then(async (result) => {

                        if(result.error){

                            setLoading(false);
                            setPaymentError("Autorizace vaši platby neproběhla v pořádku. Platbu je třeba provést znovu.");

                            //await this.sendPaymentCanceledEmail(client,cpd.data.createPayment.customerPaymentID,membershipDurationID,name,surname,email,domainData.domainName,tel);                                   
                
                        }else{

                            //úspěšně zapolaceno
                            setLoading(false);
                            setSuccesPayment(true);

                        }
                    });
                }

            }catch(err){
                setLoading(false);
                setPaymentError(err);
            } 
        }
    }

    sendPaymentCanceledEmail(client,customerPaymentID,membershipDuration,name,surname,email,domain,tel){

        return new Promise(async (resolve,rejected) => {
            
            try{

                var data = await client.mutate({
                    mutation:SEND_PAYMENT_CANCELED_EMAIL,
                    variables:{customerPaymentID,membershipDuration,name,surname,email,domain,tel}
                });

                resolve(data);

            }catch(err){

                var helper = new Helper();
                err = helper.getApolloErrorText(err);

                rejected(err);
            }
        });

    }

    createPayment(client,token,customerID,membershipDuration,name,surname,email,city,street,zip,autoPayment,domain,tel,serviceID,customerServiceID){

        return new Promise(async (resolve,rejected) => {
            
            try{

                var data = await client.mutate({
                    mutation:CREATE_PAYMENT,
                    variables:{token,customerID,membershipDuration,name,surname,email,city,street,zip,autoPayment,domain,tel,serviceID,customerServiceID}
                });

                resolve(data);

            }catch(err){

                var helper = new Helper();
                err = helper.getApolloErrorText(err);

                rejected(err);
            }
        });
    }
}

export default StripeClass;