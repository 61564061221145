import ChatMessageImage from "./ChatMessageImage";
import moment from 'moment';
import {SERVER_URL} from '../../../Config/index';
import user from '../../../Public/Images/user.svg';

const ChatMessage = ({vs,containerRef,item:{fromCustomer,image,width,height,message,dateAdd,toGroupID,readedInGroup,readed}}) => {

    var newWidth = 0;
    var newWHeight = 0;
    
    if(image && containerRef.current)
    {
        const containerWidth = containerRef.current.content.clientWidth;

        var aspectRatio = width / height;
        if(width < height)
        {
            newWHeight = 400;
            newWidth = Math.round(newWHeight * aspectRatio); 
        }
        else
        {
            newWidth = 400;
            if(containerWidth <= newWidth + 200)newWidth = containerWidth / 100 * 75;
            newWHeight = Math.round(newWidth / aspectRatio); 
        }
    }

    return(
        <>
            {vs ?
                <div className="image">
                    <img src={(fromCustomer.photo != "" ? SERVER_URL + "/Public/Images/Avatar/stredni_" + fromCustomer.photo : user)} />
                </div>
            :null}

            <div style={(newWidth != 0 ? {width:newWidth + "px"} : null)}  className={"current-message-container" + (newWidth == 0 ? " mw-75 " : "")  + (vs && ((readed == 0 && toGroupID == 0) || (toGroupID != 0 && readedInGroup == 0)) ? " not-readed" : "")}>
                
                {vs ?
                    <p className="name">{(fromCustomer.astralName != "" ? fromCustomer.astralName : fromCustomer.name)} </p>
                :null}

                {image ?
                    <ChatMessageImage name={image} width={newWidth} height={newWHeight} />
                :null}

                <div className="current-message-content">  
                    {message ?       
                        <p className={(image ? "with-margin" : "")} dangerouslySetInnerHTML={{__html:message}}></p>
                    :null}
                    <div className={"current-message-date" + (image && !message ? " no-border" : "") + (!vs ? " my" : "")}>{moment(dateAdd).format("D.M.YYYY HH:mm:ss")}</div>
                </div>
                
            </div>
            {!vs ?
                <div className="image right">
                    <img src={(fromCustomer.photo != "" ? SERVER_URL + "/Public/Images/Avatar/stredni_" + fromCustomer.photo : user)} />
                </div>
            :null}
        </>
    )

}

export default ChatMessage;