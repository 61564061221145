/**
 * soubor se seznamem veškerých graphql dotazů pro změnu hesla
 */
import gql from 'graphql-tag';

export const GET_CUSTOMER_DAILY_DATA = gql`
    query GetCustomerDailyData($customerID:ID,$offset:Int,$limit:Int,$messageType:Int){
        
        allCustomerDailyPosts(customerID:$customerID,offset:$offset,limit:$limit,messageType:$messageType){
            customerDailyPostID
            guideID
            dateAdd
            text
            readByGuide
            customerID
            guide{
                name
                surname
                astralName
            }
        }
        allCustomerDailyPostsCount(customerID:$customerID,messageType:$messageType)
        allMessageTypeNotReadMessagesCount(customerID:$customerID){
            messages
            dreams
            sync
            intangibleExperiences
            negativCharacteristics
            positiveCharacteristics
            charismaticEssence
            joys
            transformation
            lifeValues
            meaningOfLife
            wishes
            dreamEightPlusOne
            actor
        }
        customerContext{
            customerTypeID
            customerID
            myMotivation
        }
    }
`;

export const GET_CUSTOMER_DAILY_POST = gql`
    query GetCustomerDailyPost($customerDailyPostID:ID){
        customerDailyPost(customerDailyPostID:$customerDailyPostID){
            text
        }
    }
`;

export const ADD_CUSTOMER_DAILY_POST = gql`
    mutation AddCustomerDailyPost($customerDailyPostID:ID, $customerID:ID!,$guideID:ID,$text:String,$messageType:Int){
        addEditCustomerDailyPost(customerDailyPostID:$customerDailyPostID,customerID:$customerID,guideID:$guideID,text:$text,messageType:$messageType){
            customerDailyPostID
            guideID
            dateAdd
            text
            guide{
                name
                surname
                astralName
            }
        }
    }
`;

export const DELETE_CUSTOMER_DAILY_POST = gql`
    mutation DeleteCustomerDailyPost($customerDailyPostID:ID){
        deleteCustomerDailyPost(customerDailyPostID:$customerDailyPostID)
    }
`;

export const UPDATE_NOT_READ_POSTS = gql`
    mutation UpdateNotReadPosts($customerID:ID,$messageType:ID){
        updateNotReadPosts(customerID:$customerID,messageType:$messageType)
    }
`;