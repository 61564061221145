import React from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { UseTheme } from '../../../GlobalComponents/Theme';

const TinyMCEEditor = (props) => {

    const {theme} = UseTheme();

    var options = {

        plugins: 'link code paste emoticons lists textcolor',
        toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | forecolor backcolor | formatselect | numlist bullist checklist | link | blockquote | emoticons | code',
        paste_as_text: true,
        language_url : '/langs/cs.js',
        language: "cs",
        body_class: 'tiny-body',
        height:1000,
        link_assume_external_targets: true
    
    }

    if(theme == 2)
    {
        options.skin= "oxide-dark";
        options.content_css =  ["dark",'/css/tinymce_dark.css?2'];
    }

    return(

        <Editor
            name="description"
            apiKey = '9z3phhnyq7jkeeqztql1euovfotvpd2gdduygtdpk14wu4ij'
            value={props.initialValue}
            init={options}
            onEditorChange={(content) => props.onChange(content,"text")}
        />
        
       
    )  

}

export default TinyMCEEditor;  