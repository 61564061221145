import React,{ Component } from 'react';
import { Mutation, ApolloConsumer,withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import {GET_CUSTOMER_FIRST_VIDEO} from '../../Video/Queries/video';
import translate from '../../Translations/index';

const LOGIN_USER = gql`
  mutation login($email: String!,$password: String!) {
    login(email: $email,password: $password){
        loginToken
        customerID
        customer{
            name
            surname
            customerID
            membershipTo
            customerTypeID
            confirm
            hasCompletedQuestionnaire
        }
    }
  }
`;

class Login extends Component{

    constructor(props){
        super(props);
    }
  
    render(){
        return (
            <ApolloConsumer>
                {client => (
                    <Mutation
                    mutation={LOGIN_USER}
                    onCompleted={async (data) => {

                        if(!data.login)this.props.error(translate.t('WronCredentialInserted'));
                        else{

                            if(data.login.customer.confirm == 1){
                                localStorage.setItem('token', data.login.loginToken);
                                localStorage.setItem('loggedUser', data.login.customer.name+" "+data.login.customer.surname);
                                localStorage.setItem('membershipTo', data.login.customer.membershipTo);
                                client.writeData({ data: { isLoggedIn: true } });

                                var url = localStorage.getItem("urlToShow");
                                localStorage.setItem("urlToShow", "");

                                if(data.login.customer.hasCompletedQuestionnaire == 1 || data.login.customer.membershipTo == null){
                                
                                    if(url && url != ""){
                                        this.props.history.push(url);
                                    }else{
                                        
                                        if(data.login.customer.membershipTo == null){
                                            this.props.history.push('/aula/bbf3d1e8866defe9b5b63c7fd2de6e88');
                                        }else{
                                            if(data.login.customer.customerTypeID != 3 && data.login.customer.customerTypeID != 4){
                                                this.props.history.push('/elite');
                                            }else{
                                                this.props.history.push('/affiliate');
                                            }
                                        }
                                        /*
                                            if(data.login.customer.customerTypeID != 3 && data.login.customer.customerTypeID != 4){
                                                this.props.history.push('/daily');
                                            }else{
                                                this.props.history.push('/affiliate');
                                            }
                                        */
                                    }
                                }else{
                                    this.props.history.push('/questionnaire');
                                }
                            }else{
                                this.props.error(translate.t('NotConfirmedAccount'));
                            }
                        }
                    }}
                    >
                    {(login, { loading, error }) => {
                        // this loading state will probably never show, but it's helpful to
                        // have for testing
                        if (loading) return "";
                        if (error) return this.props.error(error);

                        return <button type="button" onClick={() => this.props.login(login)} className="btn btn-primary btn-block">{translate.t('LogIn')}</button>;
                    }}
                    </Mutation>
                )}
            </ApolloConsumer>
        );
    }
}

export default withApollo(Login);