import React,{ Component,Fragment,useRef,useEffect,useState } from 'react';
import { withApollo,useQuery } from 'react-apollo';
import {Link} from 'react-router-dom';
import translate from '../../Translations/index';
import user from '../../../Public/Images/Icons/user.webp';
import {SERVER_URL} from '../../../Config/index';

const ProfileAvatar = (props) => {

    const imageContainer = useRef(null);
    var customer = props.customer;

    const [imgWidth,setImgWidth] = useState(0);

    useEffect(() => {
        if(customer.eliteVSwinnerCount > 0)setImgWidth(imageContainer.current.offsetWidth);
    },[]);

    const angles = [];

    const crowns = Math.floor(customer.eliteVSwinnerCount / 5);
    const stars = customer.eliteVSwinnerCount % 5;

    for (var i = 0; i < crowns; i++) {
        angles.push({
            i:i,
            type:"👑"
        });
    }

    for (var j = i; j < stars + i; j++) {
        angles.push({
            i:j,
            type:"⭐"
        });
    }

    return(
        

        <div className={(props.inRow ? "profile-avatar-row" : "profile-avatar" + (props.isFluid ? " fluid" : ""))}>
            <div className={"avatar-image mb-3"  + (props.inRow ? " in-row mr-3" : "")}>
                {props.withLink ?
                    (props.aLink ?
                        <a href={"/vs-list/" + customer.regCode}><img ref={imageContainer}  src={(customer.photo && customer.photo != "" ? SERVER_URL + "/Public/Images/Avatar/stredni_" + customer.photo : user)} /></a>
                        :
                        <Link to={"/vs-list/" + customer.regCode}><img ref={imageContainer}  src={(customer.photo && customer.photo != "" ? SERVER_URL + "/Public/Images/Avatar/stredni_" + customer.photo : user)} /></Link>
                    )
                :
                    <img ref={imageContainer}  src={(customer.photo && customer.photo != "" ? SERVER_URL + "/Public/Images/Avatar/stredni_" + customer.photo : user)} />
                }

                {imgWidth != 0 ?

                    (angles.map((item,index) => (

                        <div key={index} className="star" style={{
                            left: imgWidth/2 - (Math.cos(Math.PI * (item.i/12) + Math.PI/6) * imgWidth/2) -imgWidth/14,
                            top: imgWidth/2 - (Math.sin(Math.PI * (item.i/12) + Math.PI/6) * imgWidth/2) -imgWidth/14,
                            fontSize: imgWidth/10 + "px"
                        }}>{item.type}</div>

                    )))
                    
                :null}
                
            </div>
            {props.inRow ?
        
                <div className="info">
                    <p className="main-name">
                        {props.withLink ?
                            (props.aLink ?
                                <a href={"/vs-list/" + customer.regCode}>{(customer.astralName != "" ? customer.astralName : customer.name)}</a>
                            :
                                <Link to={"/vs-list/" + customer.regCode}>{(customer.astralName != "" ? customer.astralName : customer.name)}</Link>  
                            )
                        :
                            (customer.astralName != "" ? customer.astralName : customer.name)
                        }
                    </p> 
                    <p><strong>{customer.totalEvsPoint ? customer.totalEvsPoint : 0}</strong> { (!customer.totalEvsPoint || customer.totalEvsPoint == 0 || customer.totalEvsPoint > 4 ? translate.t("bodu") : (customer.totalEvsPoint == 1 ? translate.t("bod") : translate.t("body")))}</p>                                     
                </div>

            :
                <>
                    <div className="name-container">
                        <p className="main-name">
                            {
                                (props.withLink ?
                                    (props.aLink ?
                                        <a href={"/vs-list/" + customer.regCode}>{(customer.astralName != "" ? customer.astralName : customer.name)}</a>
                                    :
                                        <Link to={"/vs-list/" + customer.regCode}>{(customer.astralName != "" ? customer.astralName : customer.name)}</Link> 
                                    )

                                :
                                    (customer.astralName != "" ? customer.astralName : customer.name) 
                                )
                            }
                        </p>
                        <p className="sub-name">{customer.introduction}</p>
                        
                    </div>
                    <div className={"sub-info" + (props.showPoints ? " text-center" : "")}>

                        {props.showPoints ?

                            <>
                                <strong>{customer.totalEvsPoint ? customer.totalEvsPoint : 0}</strong> { (!customer.totalEvsPoint || customer.totalEvsPoint == 0 || customer.totalEvsPoint > 4 ? translate.t("bodu") : (customer.totalEvsPoint == 1 ? translate.t("bod") : translate.t("body")))}
                            </>
                        :

                            <>
                                {customer.city ?
                                <div>
                                    <div><strong>Město</strong></div> 
                                    <div>{customer.city}</div> 
                                </div>
                                :null}

                                <div className={"degree" + (!customer.city ? " alone" :"")}>

                                    {customer.customerTypeID == 2 || customer.customerTypeID == 5 ?
                                        <div><strong>{translate.t("GuideVS")}</strong></div>
                                    :
                                        <>
                                            <div><strong>Stupeň</strong></div>
                                            <div>{customer.customerLastMaterial.longName}</div>
                                        </>
                                    }

                                </div>
                            </>
                            
                        }
                    </div>
                </>
            }
        </div>
                
    );

}

export default withApollo(ProfileAvatar);