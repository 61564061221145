import React,{useEffect, useState} from 'react';
import {withApollo,useQuery,useMutation } from 'react-apollo';
import {GET_CUSTOMER_DAILY_DATA,DELETE_CUSTOMER_DAILY_POST,UPDATE_NOT_READ_POSTS} from '../Queries/daily';
import {GET_HEADER_DATA} from '../../../GlobalQueries/globalQueries';
import {GET_MY_VS_DATA} from '../../Guide/Queries/myvs';
import Error from '../../../GlobalComponents/Error';
import Loading from '../../../GlobalComponents/Loading';
import translate from '../../Translations/index';
import edit from '../../../Public/Images/Icons/edit.webp';
import reply from '../../../Public/Images/Icons/reply.webp';
import remove from '../../../Public/Images/Icons/close.webp';
import book from '../../../Public/Images/book.svg';
import AddPost from './AddPost';
import moment from 'moment';
import Helper from '../../../Library/helper';
import ModalNotification from '../../../GlobalComponents/ModalNotification';
import DailyLib from '../Library/daily';
import down from '../../../Public/Images/Icons/arrows_down.webp';

const AllCustomerDailyPosts = (props) => {

    var dailyLib = new DailyLib(props);

    const [messageType, setMessageType] = useState({
        type: 1,
        name: translate.t("Messages"),
        showMobileTabs:false,
        updateReadedPosts: false
    });

    var offset = 0;
    var limit = 20;
    var variables = {
        customerID: (props.customerID ? props.customerID : 0),
        offset,
        limit,
        messageType:messageType.type
    }

    var showTabsByMaterial = {
        dreams:false,
        sync:false,
        intangibleExperiences:false,
        negativCharacteristics:false,
        positiveCharacteristics:false,
        charismaticEssence:false,
        joys:false,
        lifeValues:false,
        transformation:false,
        meaningOfLife:false,
        wishes:false,
        dreamEightPlusOne:false,
        actor:false
    }

    for(let i = 0; i < props.materials.length;i++){
        if(props.materials[i].materialID == 2)showTabsByMaterial.dreams = true;
        if(props.materials[i].materialID == 2)showTabsByMaterial.sync = true;
        if(props.materials[i].materialID == 2)showTabsByMaterial.intangibleExperiences = true;

        if(props.materials[i].materialID == 3 || props.materials[i].materialID == 89)showTabsByMaterial.negativCharacteristics = true;
        if(props.materials[i].materialID == 4 || props.materials[i].materialID == 89)showTabsByMaterial.positiveCharacteristics = true;

        if(props.materials[i].materialID == 9)showTabsByMaterial.charismaticEssence = true;
        if(props.materials[i].materialID == 9)showTabsByMaterial.joys = true;

        if(props.materials[i].materialID == 10)showTabsByMaterial.transformation = true;

        if(props.materials[i].materialID == 11)showTabsByMaterial.lifeValues = true;

        
        if(props.materials[i].materialID == 12)showTabsByMaterial.meaningOfLife = true;
        if(props.materials[i].materialID == 12)showTabsByMaterial.wishes = true;

        if(props.materials[i].materialID == 15)showTabsByMaterial.dreamEightPlusOne = true;
        if(props.materials[i].materialID == 39)showTabsByMaterial.actor = true;
    }

    var showTabsContentToVS = [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false];

    if(props.customerPrivacy){

        for(let i = 0; i < props.customerPrivacy.length; i++){
            showTabsContentToVS[props.customerPrivacy[i].privacyID - 1] = true;
        }

    }

    const [showAdd, setShowAdd] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [postIDToDelete, setPostIDToDelete] = useState(false);
    const [selectedPostID, setSelectedPostID] = useState(0);
    const [selectedText, setSelectedText] = useState("");   

    const {data,loading,error,refetch,fetchMore} = useQuery(GET_CUSTOMER_DAILY_DATA,{
        fetchPolicy: 'network-only',
        variables
    });

    const [deleteCustomerDailyPost,{error:deleteError,loading:deleteLoading}] = useMutation(DELETE_CUSTOMER_DAILY_POST,{
        onCompleted: (data) => {

            const { allCustomerDailyPosts } = props.client.readQuery({ query: GET_CUSTOMER_DAILY_DATA,variables: variables });

            var arr = [...allCustomerDailyPosts];
        
            arr.forEach((item,index) => {
                
                if(item.customerDailyPostID == data.deleteCustomerDailyPost){
                    arr = [...arr.slice(0,index),...arr.slice(index + 1)]
                }

            });
            props.client.writeQuery({ 
                query:GET_CUSTOMER_DAILY_DATA,
                variables:variables,
                data:{
                    allCustomerDailyPosts: arr
                } 
            });        
            
        }

    });

    const [updateNotReadedPosts,{error:updateError,loading:updateLoading}] = useMutation(UPDATE_NOT_READ_POSTS,{
        onCompleted: (data) => {

            
            const { customerContext } = props.client.readQuery({ query: GET_HEADER_DATA});
            var custContext = {...customerContext};
            if(custContext.totalNotReadPostOfMyVS > 0){
                custContext.totalNotReadPostOfMyVS -= data.updateNotReadPosts;

                props.client.writeQuery({ 
                    query:GET_HEADER_DATA,
                    data:{
                        customerContext:custContext
                    } 
                }); 
            }

            try{
                const { allGuidesCustomer } = props.client.readQuery({ query: GET_MY_VS_DATA });
                            
                var arr = [...allGuidesCustomer];
                arr.forEach((item,index) => {
                    
                    if(item.customerID == props.customerID){
                        arr[index] = {...arr[index],notReadByGuidePostCount:arr[index].notReadByGuidePostCount - data.updateNotReadPosts};
                    }

                });

                props.client.writeQuery({ 
                    query:GET_MY_VS_DATA,
                    data:{
                        allGuidesCustomer: arr
                    } 
                });  
            
            }catch(err){

            }
                        
            
            
        }

    });

    useEffect(() => {

        if(!props.isVSList && data && (data.customerContext.customerTypeID == 2 || data.customerContext.customerTypeID == 5) && props.customerID != data.customerContext.customerID && !messageType.updateReadedPosts){
            
            updateNotReadedPosts({
                variables:{
                    customerID:props.customerID,
                    messageType:messageType.type
                }
            })
            setMessageType({...messageType, updateReadedPosts:true});
        }

    },[data])

    var err = "";
    if(error || deleteError || updateError){

        var helper = new Helper();
        err = helper.getApolloErrorText(error || deleteError || updateError);  

    }

    return(
        <div>

            {err ?
                <Error text = {err} />
            :
                <div className="daily-posts">
                    <div className={"card" + (props.myMotivation != "" ? "" : (props.isVSList ? " no-margin" : " small-margin"))}>

                        {!props.isVSList ?
                            <div className={"card-header" + (props.myMotivation != "" ? "" : " full-radius")}>
                                {translate.t("DAILY")}
                                {!props.isVSList ?
                                    <button onClick={() => {setSelectedPostID(0);setSelectedText("");setShowAdd(true);}} className="btn btn-primary ml-auto btn-thiner">{translate.t("AddNewPost")}</button>
                                :null}
                            </div>
                        :null}

                        {props.myMotivation != "" ?
                            <div className="card-body">

                                <div className="alert alert-info no-margin"><strong>Motivace:</strong> {((props.isVSList && showTabsContentToVS[17]) || !props.isVSList ? props.myMotivation : translate.t("NoAllowedContent"))}</div>
                                
                            </div>
                        :null}

                    </div>

                    <div className="position-relative">

                        <div className="mobile-tabs" onClick={() => setMessageType({...messageType,showMobileTabs:!messageType.showMobileTabs})}>
                            {messageType.name}
                            <img className="left" src={down} />
                            <img className="right" src={down} />
                        </div>

                        <ul className={"nav nav-tabs " + (messageType.showMobileTabs ? "open" : "")}>

                           
                            <li className="nav-item mb-3">
                                <a onClick={(e) => {e.preventDefault();setMessageType({updateReadedPosts:false,showMobileTabs:false,type:1,name:translate.t("Messages")})}} className={"nav-link" + (messageType.type == 1 ?  " active" : "")}>
                                    {translate.t("Messages")}
                                    {data && props.guideID != 0 && data.allMessageTypeNotReadMessagesCount.messages > 0 ? <div className="badge badge-danger">{data.allMessageTypeNotReadMessagesCount.messages}</div> : null}
                                </a>
                            </li>

                            {showTabsByMaterial.dreams ?
                                <li className="nav-item mb-3">
                                    <a onClick={(e) => {e.preventDefault();setMessageType({updateReadedPosts:false,showMobileTabs:false,type:2,name:translate.t("Dreams")})}} className={"nav-link" + (messageType.type == 2 ?  " active" : "")}>
                                        {translate.t("Dreams")}
                                        {data && props.guideID != 0 && data.allMessageTypeNotReadMessagesCount.dreams > 0 ? <div className="badge badge-danger">{data.allMessageTypeNotReadMessagesCount.dreams}</div> : null}
                                        {!props.isVSList && props.guideID == 0 && props.lastMaterialID == 2 && <div className="badge badge-danger">{translate.t("New")}</div>}
                                    </a>
                                </li>
                            :null}

                            {showTabsByMaterial.sync ?
                                <li className="nav-item mb-3">
                                    <a onClick={(e) => {e.preventDefault();setMessageType({updateReadedPosts:false,showMobileTabs:false,type:3,name:translate.t("Sync")})}} className={"nav-link" + (messageType.type == 3 ?  " active" : "")}>
                                        {translate.t("Sync")}
                                        {data && props.guideID != 0 && data.allMessageTypeNotReadMessagesCount.sync > 0 ? <div className="badge badge-danger">{data.allMessageTypeNotReadMessagesCount.sync}</div> : null}
                                        {!props.isVSList && props.guideID == 0 && props.lastMaterialID == 2 && <div className="badge badge-danger">{translate.t("New")}</div>}
                                    </a>
                                </li>
                            :null}

                            {showTabsByMaterial.intangibleExperiences ?
                                <li className="nav-item mb-3">
                                    <a onClick={(e) => {e.preventDefault();setMessageType({updateReadedPosts:false,showMobileTabs:false,type:4,name:translate.t("IntangibleExperiences")})}} className={"nav-link" + (messageType.type == 4 ?  " active" : "")}>
                                        {translate.t("IntangibleExperiences")}
                                        {data && props.guideID != 0 && data.allMessageTypeNotReadMessagesCount.intangibleExperiences > 0 ? <div className="badge badge-danger">{data.allMessageTypeNotReadMessagesCount.intangibleExperiences}</div> : null}
                                        {!props.isVSList && props.guideID == 0 && props.lastMaterialID == 2 && <div className="badge badge-danger">{translate.t("New")}</div>}
                                    </a>
                                </li>
                            :null}

                            {showTabsByMaterial.negativCharacteristics ?
                                <li className="nav-item mb-3">
                                    <a onClick={(e) => {e.preventDefault();setMessageType({updateReadedPosts:false,showMobileTabs:false,type:5,name:translate.t("NegativCharacteristics")})}} className={"nav-link" + (messageType.type == 5 ?  " active" : "")}>
                                        {translate.t("NegativCharacteristics")}
                                        {data && props.guideID != 0 && data.allMessageTypeNotReadMessagesCount.negativCharacteristics > 0 ? <div className="badge badge-danger">{data.allMessageTypeNotReadMessagesCount.negativCharacteristics}</div> : null}
                                        {!props.isVSList && props.guideID == 0 && props.lastMaterialID == 3 && <div className="badge badge-danger">{translate.t("New")}</div>}
                                    </a>
                                </li>
                            :null}
                            {showTabsByMaterial.positiveCharacteristics ?
                                <li className="nav-item mb-3">
                                    <a onClick={(e) => {e.preventDefault();setMessageType({updateReadedPosts:false,showMobileTabs:false,type:6,name:translate.t("PositiveCharacteristics")})}} className={"nav-link" + (messageType.type == 6 ?  " active" : "")}>
                                        {translate.t("PositiveCharacteristics")}
                                        {data && props.guideID != 0 && data.allMessageTypeNotReadMessagesCount.positiveCharacteristics > 0 ? <div className="badge badge-danger">{data.allMessageTypeNotReadMessagesCount.positiveCharacteristics}</div> : null}
                                        {!props.isVSList && props.guideID == 0 && props.lastMaterialID == 4 && <div className="badge badge-danger">{translate.t("New")}</div>}
                                    </a>
                                </li>
                            :null}
                            {showTabsByMaterial.charismaticEssence ?
                                <li className="nav-item mb-3">
                                    <a onClick={(e) => {e.preventDefault();setMessageType({updateReadedPosts:false,showMobileTabs:false,type:7,name:translate.t("CharismaticEssence")})}} className={"nav-link" + (messageType.type == 7 ?  " active" : "")}>
                                        {translate.t("CharismaticEssence")}
                                        {data && props.guideID != 0 && data.allMessageTypeNotReadMessagesCount.charismaticEssence > 0 ? <div className="badge badge-danger">{data.allMessageTypeNotReadMessagesCount.charismaticEssence}</div> : null}
                                        {!props.isVSList && props.guideID == 0 && props.lastMaterialID == 9 && <div className="badge badge-danger">{translate.t("New")}</div>}
                                    </a>
                                </li>
                            :null}
                            {showTabsByMaterial.joys ?
                                <li className="nav-item mb-3">
                                    <a onClick={(e) => {e.preventDefault();setMessageType({updateReadedPosts:false,showMobileTabs:false,type:8,name:translate.t("30Joys")})}} className={"nav-link" + (messageType.type == 8 ?  " active" : "")}>
                                        {translate.t("30Joys")}
                                        {data && props.guideID != 0 && data.allMessageTypeNotReadMessagesCount.joys > 0 ? <div className="badge badge-danger">{data.allMessageTypeNotReadMessagesCount.joys}</div> : null}
                                        {!props.isVSList && props.guideID == 0 && props.lastMaterialID == 9 && <div className="badge badge-danger">{translate.t("New")}</div>}
                                    </a>
                                </li>
                            :null}
                            {showTabsByMaterial.transformation ?
                                <li className="nav-item mb-3">
                                    <a onClick={(e) => {e.preventDefault();setMessageType({updateReadedPosts:false,showMobileTabs:false,type:19,name:translate.t("Transformation")})}} className={"nav-link" + (messageType.type == 19 ?  " active" : "")}>
                                        {translate.t("Transformation")}
                                        {data && props.guideID != 0 && data.allMessageTypeNotReadMessagesCount.transformation > 0 ? <div className="badge badge-danger">{data.allMessageTypeNotReadMessagesCount.transformation}</div> : null}
                                        {!props.isVSList && props.guideID == 0 && props.lastMaterialID == 10 && <div className="badge badge-danger">{translate.t("New")}</div>}
                                    </a>
                                </li>
                            :null}
                            {showTabsByMaterial.lifeValues ?
                                <li className="nav-item mb-3">
                                    <a onClick={(e) => {e.preventDefault();setMessageType({updateReadedPosts:false,showMobileTabs:false,type:9,name:translate.t("LifeValues")})}} className={"nav-link" + (messageType.type == 9 ?  " active" : "")}>
                                        {translate.t("LifeValues")}
                                        {data && props.guideID != 0 && data.allMessageTypeNotReadMessagesCount.lifeValues > 0 ? <div className="badge badge-danger">{data.allMessageTypeNotReadMessagesCount.lifeValues}</div> : null}
                                        {!props.isVSList && props.guideID == 0 && props.lastMaterialID == 11 && <div className="badge badge-danger">{translate.t("New")}</div>}
                                    </a>
                                </li>
                            :null}
                            
                            {showTabsByMaterial.meaningOfLife ?
                                <li className="nav-item mb-3">
                                    <a onClick={(e) => {e.preventDefault();setMessageType({updateReadedPosts:false,showMobileTabs:false,type:10,name:translate.t("meaningOfLife")})}} className={"nav-link" + (messageType.type == 10 ?  " active" : "")}>
                                        {translate.t("meaningOfLife")}
                                        {data && props.guideID != 0 && data.allMessageTypeNotReadMessagesCount.meaningOfLife > 0 ? <div className="badge badge-danger">{data.allMessageTypeNotReadMessagesCount.meaningOfLife}</div> : null}
                                        {!props.isVSList && props.guideID == 0 && props.lastMaterialID == 12 && <div className="badge badge-danger">{translate.t("New")}</div>}
                                    </a>
                                </li>
                            :null}
                            {showTabsByMaterial.wishes ?
                                <li className="nav-item mb-3">
                                    <a onClick={(e) => {e.preventDefault();setMessageType({updateReadedPosts:false,showMobileTabs:false,type:11,name:translate.t("108wishes")})}} className={"nav-link" + (messageType.type == 11 ?  " active" : "")}>
                                        {translate.t("108wishes")}
                                        {data && props.guideID != 0 && data.allMessageTypeNotReadMessagesCount.wishes > 0 ? <div className="badge badge-danger">{data.allMessageTypeNotReadMessagesCount.wishes}</div> : null}
                                        {!props.isVSList && props.guideID == 0 && props.lastMaterialID == 12 && <div className="badge badge-danger">{translate.t("New")}</div>}
                                    </a>
                                </li>
                            :null}
                            {showTabsByMaterial.dreamEightPlusOne ?
                                <li className="nav-item mb-3">
                                    <a onClick={(e) => {e.preventDefault();setMessageType({updateReadedPosts:false,showMobileTabs:false,type:12,name:translate.t("8+1dream")})}} className={"nav-link" + (messageType.type == 12 ? " active" : "")}>
                                        {translate.t("8+1dream")}
                                        {data && props.guideID != 0 && data.allMessageTypeNotReadMessagesCount.dreamEightPlusOne > 0 ? <div className="badge badge-danger">{data.allMessageTypeNotReadMessagesCount.dreamEightPlusOne}</div> : null}
                                        {!props.isVSList && props.guideID == 0 && props.lastMaterialID == 15 && <div className="badge badge-danger">{translate.t("New")}</div>}
                                    </a>
                                </li>
                            :null}
                            {showTabsByMaterial.actor ?
                                <li className="nav-item mb-3">
                                    <a onClick={(e) => {e.preventDefault();setMessageType({updateReadedPosts:false,showMobileTabs:false,type:13,name:translate.t("Actor")})}} className={"nav-link" + (messageType.type == 13 ? " active" : "")}>
                                        {translate.t("Actor")}
                                        {data && props.guideID != 0 && data.allMessageTypeNotReadMessagesCount.actor > 0 ? <div className="badge badge-danger">{data.allMessageTypeNotReadMessagesCount.actor}</div> : null}
                                        {!props.isVSList && props.guideID == 0 && props.lastMaterialID == 39 && <div className="badge badge-danger">{translate.t("New")}</div>}
                                    </a>
                                </li>
                            :null}
                        </ul>

                    </div>

                    {loading ?
                        <Loading />
                    :
                        <>
                        {(showTabsContentToVS[messageType.type - 1] && props.customerPrivacy) || !props.customerPrivacy || data.customerContext.customerTypeID == 5 ? 

                            <>
                        
                            {data.allCustomerDailyPosts && data.allCustomerDailyPosts.length > 0 ?

                                <>
                                
                                    {data.allCustomerDailyPosts.map((item,index) => (

                                        <div key={index} className={"card post " + ((data.customerContext.customerTypeID == 2 || data.customerContext.customerTypeID == 5) && item.readByGuide == 0 && item.customerID != data.customerContext.customerID  ? "new" : "")}>

                                            <div className="card-body">

                                                <div className="header">
                                                    <div> {moment(item.dateAdd).format("DD.MM.YYYY HH:mm:ss")} <span className="from">{(item.guideID != 0 ? "OD: " + (item.guide.astralName ? item.guide.astralName : item.guide.name + " " + item.guide.surname) : "")}</span></div>
                                                    <div className="ml-auto">

                                                        <img onClick={() => {
                                                            setSelectedText(item.text);
                                                            setShowAdd(true);
                                                            setSelectedPostID(0);
                                                        }} title={translate.t("Reply")} src={reply} />

                                                        {data.customerContext.customerTypeID == 2 || data.customerContext.customerTypeID == 5 || (data.customerContext.customerID == item.customerID && item.guideID == 0) ?
                                                            <>
                                                                <img onClick={() => {
                                                                    setSelectedPostID(item.customerDailyPostID);
                                                                    setShowAdd(true);
                                                                }} title={translate.t("EditPost")} src={edit} />

                                                                <img onClick={() => {
                                                                    setShowDelete(true);
                                                                    setPostIDToDelete(item.customerDailyPostID);
                                                                }} title={translate.t("DeletePost")} src={remove} />
                                                            </>
                                                        :null}
                                                    </div>
                                                </div>

                                                <div className = "content tiny-content" dangerouslySetInnerHTML={{__html:item.text}}></div>
                                                
                                            </div>

                                        </div>

                                    ))}

                                    {data.allCustomerDailyPostsCount > data.allCustomerDailyPosts.length ?

                                        <p className="text-center mt-5">
                                            <button 
                                                onClick={async  () => await fetchMore({
                                                    variables: {
                                                        customerID: (props.customerID ? props.customerID : 0),
                                                        offset:data.allCustomerDailyPosts.length,
                                                        limit
                                                    },
                                                    updateQuery: (prev, { fetchMoreResult }) => {
                                                    
                                                    if (!fetchMoreResult) return prev.allCustomerDailyPosts;
                                                    return Object.assign({}, prev, {
                                                        allCustomerDailyPosts: [...prev.allCustomerDailyPosts, ...fetchMoreResult.allCustomerDailyPosts]
                                                    });
                                                    }
                                                })} 
                                                className="btn btn-primary">
                                                {translate.t("LoadMore")}
                                            </button>
                                        </p>
                                    
                                    :
                                    null}
                                
                                </>

                            :

                                <div className="card">

                                    <div className="card-body">

                                        <div className="alert alert-warning text-center no-margin">{translate.t("NoMessagesInThisSection")}</div>
                                        
                                    </div>

                                </div>

                            }

                            {showAdd ?
                                <AddPost showTabsByMaterial={showTabsByMaterial} messageType={messageType.type} selectedText={selectedText} variables={variables} refetchPosts = {refetch} selectedPostID = {selectedPostID} customerID = {props.customerID} guideID = {props.guideID} setShowAdd={setShowAdd} />
                            :null}
                            {showDelete ?
                                <ModalNotification yesNo = {true} text={translate.t("DoYouRealyWantToDeletePost")} callback={(act) => dailyLib.deleteCustomerdailyPost(act,postIDToDelete,deleteCustomerDailyPost,setShowDelete)} />
                            :null}

                            </>
                            :
                                <div className="card">

                                    <div className="card-body">

                                        <div className="alert alert-warning text-center no-margin">{translate.t("NoAllowedContent")}</div>
                                        
                                    </div>

                                </div>

                            }
                        </>
                    }
                    
                    
                </div>
 
            }

        </div>
    )

}

export default withApollo(AllCustomerDailyPosts);