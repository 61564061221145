import {GET_CUSTOMER_BY_REG_CODE,UPDATE_CUSTOMER_WITH_EXTENDED_MEMBERSHIP} from '../Queries/customer';
import Helper from '../../../Library/helper';
import Notification from '../../../Library/notification';

class Customer{

    constructor(props){
        this.props = props;
    }

    changeCustomerDetails(formData,updateCustomerDetails,custData){

        if(formData.name != ""){

            if(formData.surname != ""){

                if((formData.introduction == "" && (custData.courseID != 0 && custData.lastCompletedCustomerPayment.amount == 0)) || (formData.introduction != "")){

                    updateCustomerDetails({
                        variables:formData
                    });
    
                }else{
        
                    let notify = new Notification();
                    notify.setNotification(null,"Nevyplnili jste svou vizitku.",false,true,this.props.client);
                }

            }else{
    
                let notify = new Notification();
                notify.setNotification(null,"Nevyplnili jste příjmení.",false,true,this.props.client);
            }

        }else{

            let notify = new Notification();
            notify.setNotification(null,"Nevyplnili jste jméno.",false,true,this.props.client);
        }
        
    }

    setPrivacy(privacyID,selectedPrivacy, setSelectedCategories){

        var checked = false;
        var arr = [...selectedPrivacy];

        for(let i in arr){
            if(arr[i] == privacyID){
                arr.splice(i,1);
                checked = true;
            }
        }

        if(!checked){
            arr.push(privacyID);
        }

        setSelectedCategories(arr);

    }

    getCustomerByRegCode(regCode,client){

        return new Promise(async (resolve,rejected) => {

            try{

                var data = await client.mutate({
                    mutation:GET_CUSTOMER_BY_REG_CODE,
                    variables:{regCode}
                });

                resolve(data);            

            }catch(err){

                var helper = new Helper();
                err = helper.getApolloErrorText(err);

                rejected(err);
            }

        });

    } 

    updateDataWithExtendMembership(customerData,client){

        return new Promise(async (resolve,rejected) => {

            try{

                var data = await client.mutate({
                    mutation:UPDATE_CUSTOMER_WITH_EXTENDED_MEMBERSHIP,
                    variables:customerData
                });

                resolve(data);            

            }catch(err){

                var helper = new Helper();
                err = helper.getApolloErrorText(err);

                rejected(err);
            }

        });
        
    }

    saveCustomerModalData(updateCustomerModalData,formData){

        updateCustomerModalData({
            variables:formData
        })

    }

    formHandle(e,formData,setFormData){
	  
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;

        if(n == "introduction"){
            if(v.length <= 30){
                setFormData({...formData,[n]: v});
            }
        }else{
            setFormData({...formData,[n]: v});
        }
        
        
              
    }

     openImage(e,formData,setPhoto,setFormData){
        var photo = e.target.files[0];        
        var reader = new FileReader();
        reader.onload = () => {
            var dataURL = reader.result;
            setPhoto(dataURL);
            setFormData({...formData,photo:photo});
        };
        reader.readAsDataURL(photo);
    }

}

export default Customer;