import React, { useEffect, useState,useRef } from 'react';
import {withApollo,useMutation,useQuery } from 'react-apollo';
import translate from '../../Translations/index';
import RoomLib from '../Library/room';
import NoItems from '../../../GlobalComponents/NoItems';
import { UseSocket } from '../../Socket/Components/Socket';

const RoomsSettings = (props) => {

    var roomLib  = new RoomLib(props);

    const [allSocketRooms, setAllRooms] = useState(null);

    const {GetAllRooms,allRooms,CloseRoom} = UseSocket();

    useEffect(() => {
        GetAllRooms();
    },[])

    useEffect(() => {
        if(allRooms){
           
            var allR = [];
            for(let i in allRooms.rooms){
                allR.push({
                    name: roomLib.getRoomName(i),
                    hash:i,
                    isOpen:allRooms.rooms[i].isOpen
                })
            }

            setAllRooms(allR);      
        }

    },[allRooms])

    const closeRoom = (roomNumber) => {
        CloseRoom(roomNumber);
    }

    return(
        <div className="whole-container">

            <div className="card main">
                            
                <div className="card-header d-flex align-items-center">
                    {translate.t('Rooms')}
                </div>
                <div className="card-body">

                

                    {allSocketRooms && allSocketRooms.length > 0 ?

                        <table className="table">

                            <thead>
                                <tr>
                                    <th>{translate.t("NameOfRoom")}</th>
                                    <th className="text-right">{translate.t("State")}</th>
                                </tr>
                            </thead>

                            <tbody>

                                {allSocketRooms.map((item,index) => (

                                    <tr key={index}>
                                        <td>{item.name}</td> 
                                        <td className="text-right">
                                            {item.isOpen ? 
                                                <button onClick={() => closeRoom(item.hash)} className="btn btn-primary btn-thiner">Zavřít místnost</button>
                                            : 
                                                "Zavřeno"
                                            }
                                        </td>
                                    </tr>

                                ))}

                            </tbody>
                        </table>
                    
                    :
                        <NoItems text={"Místnosti nenalezeny"} />
                    }

                </div>
            </div>
        </div>
    )

}

export default withApollo(RoomsSettings);