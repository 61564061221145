import React,{useState,useMemo} from 'react';
import {useQuery,withApollo} from 'react-apollo';
import {GET_MY_VS_DATA} from '../Queries/myvs';
import translate from '../../Translations/index';
import Loading from '../../../GlobalComponents/Loading';
import NoItems from '../../../GlobalComponents/NoItems';
import Error from '../../../GlobalComponents/Error';
import Helper from '../../../Library/helper';
import payments from '../../../Public/Images/Icons/payments.webp';
import verified from '../../../Public/Images/Icons/verified.webp';
import CustomerModalAllDetails from '../../Customer/Components/CustomerModalAllDetails';
import moment from 'moment';
import useMyVs from '../Library/useMyVs';
import ModalNotification from '../../../GlobalComponents/ModalNotification';
import {GET_HEADER_DATA} from '../../../GlobalQueries/globalQueries';

const MyVS = ({client}) => {

    const {showNotify,error:myVsError,loading:myVsLoading,ConfirmContact,ShowConfirmNotification} = useMyVs(client);

    const [tab,SetTab] = useState(1);
    const [activeNotReadPosts,SetActivenotReadedPosts] = useState(0);
    const [activeCount,SetActiveCount] = useState(0);
    const [selectedMaterials,SetSelectedMaterials] = useState([]);
    const [notActiveNotReadPosts,SetNotActivenotReadedPosts] = useState(0);
    const {data,loading,error,refetch} = useQuery(GET_MY_VS_DATA,{
        fetchPolicy: 'network-only'
    });

    const {dataHeader} = useQuery(GET_HEADER_DATA,{
        fetchPolicy: 'network-only',
    });

    const [selectedCustomer,setSelectedCustomer] = useState(0);

    const SelectMaterial = (e,value) => {

        var checked = e.target.checked;
        var arr = [...selectedMaterials];

        if(!checked){
            for(let i in selectedMaterials){
                if(selectedMaterials[i] == value){
                    arr.splice(i,1);
                }
            }
        }else{
            arr = [value,...arr];
        }

        SetSelectedMaterials(arr);

    }

    const CheckCustomerLastMatrial = (parentID) => {

        var  check = false;
        if(selectedMaterials.length == 0 ) check = true;
        for(let m of selectedMaterials)
        {
            if(m == parentID)
                check = true;
        }

        return check;
    }

    const allCustomers = useMemo(() => {

        if(data && data.allGuidesCustomer)
        {
            var activeNotReadPosts = 0;
            var notActiveReadedPosts = 0;
            var activeCustomers = [];
            var notActiveCustomers = [];

            for(let i = 0; i < data.allGuidesCustomer.length; i++)
            {
                var check = CheckCustomerLastMatrial(data.allGuidesCustomer[i].customerLastMaterial.parentID);

                if(moment(data.allGuidesCustomer[i].membershipTo).hours(23).minutes(59).seconds(59) >= moment())
                {
                    activeNotReadPosts += data.allGuidesCustomer[i].notReadByGuidePostCount;
                    if(check)
                        activeCustomers.push(data.allGuidesCustomer[i]);
                }
                else
                {
                    notActiveReadedPosts += data.allGuidesCustomer[i].notReadByGuidePostCount;
                    if(check)
                        notActiveCustomers.push(data.allGuidesCustomer[i]);
                }
            }

            SetActivenotReadedPosts(activeNotReadPosts);
            SetNotActivenotReadedPosts(notActiveReadedPosts);

            SetActiveCount(activeCustomers.length);

            if(tab == 1)
                return activeCustomers;
            else
                return notActiveCustomers;
        }
        return [];

    },[data,tab,selectedMaterials]);

    var err = "";
    if(error || myVsError){

        var helper = new Helper();
        err = helper.getApolloErrorText(error || myVsError);  

    }
    
    return(
        <div id="my-vs" className="whole-container">

            {err != "" ?
                <Error text={err} />
            :

                <div className="card mb-3">

                    <div className="card-header d-flex align-items-center">
                        {translate.t("MyVS")}   
                    </div>
                    <div className="card-body">

                        {loading || myVsLoading ?
                            <Loading />
                        :

                            <>
                                <ul className="tabs">
                                    <li onClick={() => SetTab(1)} className={"tab-item" + (tab == 1 ? " selected" : "")}><span>{translate.t("Active")} &nbsp; ({activeCount})</span><div className={"badge" + (activeNotReadPosts != 0 ? " badge-danger" : " badge-success")}>{activeNotReadPosts}</div></li>
                                    <li onClick={() => SetTab(2)} className={"tab-item"+ (tab == 2 ? " selected" : "")}><span>{translate.t("NotActive")}</span><div className={"badge" + (notActiveNotReadPosts != 0 ? " badge-danger" : " badge-success")}>{notActiveNotReadPosts}</div></li>
                                </ul>

                                <div className="filter-data">
                                    <div className="item"><strong>FILTR:</strong></div>
                                    {data.allMaterials.map((item,index) => {

                                        var check = false;
                                        for(let val of selectedMaterials)
                                        {
                                            if(val == item.materialID)
                                                check = true;
                                        }

                                        return(

                                            <div key={item.materialID} className="form-group item">
                                                <div className="form-check form-check-inline">
                                                    <input checked = {check} onChange={(e) => SelectMaterial(e,item.materialID)} id={"m-" + item.materialID} type="checkbox" className="form-check-input" value={item.materialID} />
                                                    <label htmlFor={"m-" + item.materialID} className="form-check-label d-flex align-items-center">
                                                        {item.name}
                                                    </label>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                
                                <div className="table-responsive">

                                    <table className="table">

                                        <thead>
                                            <tr>
                                                <th>{translate.t("Name")}</th>
                                                <th className="text-center">{translate.t("AstralName")}</th>
                                                <th className="text-center">{translate.t("MembershipTo")}</th>
                                                <th className="text-center">{translate.t("CurrentLesson")}</th>
                                                <th className="text-center">{translate.t("NewLevelDate")}</th>
                                                <th className="text-center">{translate.t("NotReadMessages")}</th>
                                                <th className="text-center">{translate.t("FirstContact")}</th>
                                                <th className="text-right w-80">{translate.t("Options")}</th>
                                            </tr>
                                        </thead>

                                        <tbody>

                                            {allCustomers.map((item,index) => {

                                                return(
                                                    <tr key={index}>
                                                        <td>{item.name} {item.surname}</td>
                                                        <td className="text-center">{item.astralName}</td>
                                                        <td className="text-center">
                                                            
                                                            <div className={(moment(item.membershipTo).hours(23).minutes(59).seconds(59) >= moment() ? "badge badge-success" : "badge badge-danger")}>{(item.membershipTo != "0000-00-00 00:00:00" ? moment(item.membershipTo).format("DD.MM.YYYY") : translate.t("NoMembership"))}</div>
                                                            
                                                        </td>
                                                        <td className="text-center">{(item.customerLastMaterial ? item.customerLastMaterial.longName : "")}</td>
                                                        <td className="text-center">{(item.materialChangeDate != "0000-00-00 00:00:00" ? moment(item.materialChangeDate).format("DD.MM.YYYY") : translate.t("NoOpened"))}</td>
                                                        <td className="text-center">{
                                                            <div className={(item.notReadByGuidePostCount == 0 ? "badge badge-success" : "badge badge-danger")}>{item.notReadByGuidePostCount}</div>
                                                        }</td>
                                                        <td className="text-center">
                                                            {item.firstContactWithGuide == 1 ?
                                                                <img className="smaller" title={translate.t("Confirmed")} src={verified} />
                                                            :
                                                                <button onClick={() => ShowConfirmNotification(item.customerID)} className="btn btn-primary btn-thinest">{translate.t("Confirm")}</button>
                                                            }
                                                        </td> 
                                                        <td className="text-right w-80"><img className="cursor-pointer" onClick={() => setSelectedCustomer(item.customerID)} src={payments} /></td>  
                                                    </tr>
                                                )

                                            })}

                                        </tbody>

                                    </table>

                                    {data.allGuidesCustomer.length == 0 ?
                                        <NoItems text={translate.t("NoMyVS")} />
                                    :
                                    null}

                                </div>

                            </>
                            
                        }

                    </div>

                </div>

            }

            {selectedCustomer != 0 ?
                <CustomerModalAllDetails customerID = {selectedCustomer} saveModal={() => {setSelectedCustomer(0);refetch()}} closeModal = {() => {setSelectedCustomer(0)}} />
            :null}

            {showNotify ?
                <ModalNotification yesNo={true} callback={ConfirmContact} text={translate.t("DoYouRealyWantToConfirmThisVS")}  />
            :null}

        </div>
    );

}

export default withApollo(MyVS);