import translate from '../../Translations/index';
import meditation from '../../../Public/Images/AnimatedIcons/meditation.gif'; 
import cleanHome from '../../../Public/Images/AnimatedIcons/clean_home.gif'; 
import therapy from '../../../Public/Images/AnimatedIcons/therapy.gif'; 
import privateGuided from '../../../Public/Images/AnimatedIcons/private_guided.gif'; 
import sidrisFamily from '../../../Public/Images/AnimatedIcons/sidris_family.gif'; 
import together from '../../../Public/Images/AnimatedIcons/together.gif'; 
import web from '../../../Public/Images/AnimatedIcons/web.gif'; 
import becomeTherapist from '../../../Public/Images/AnimatedIcons/become_therapist.gif';
import astralTraveling from '../../../Public/Images/AnimatedIcons/astral_traveling.gif'; 

const Intensively = () => {

    return(
        <div className={"whole-container"}>
            <div className="row m-40">
                <div className="col-12 col-sm-6 col-xl-4 service">
                    
                    <div className="icon">
                        <img src={meditation} alt="" />
                    </div>
                    <div className="card">
                        <div className="card-header">
                            {translate.t("Audiomeditation")}
                        </div>
                        <div className="card-body">
                            <p>{translate.t("AudiomeditationText")}</p>
                            <div className="text-center">
                                <a target="_blank" href="http://audiomeditace.cz" className="btn btn-primary">{translate.t("GoToWeb")}</a>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div className="col-12 col-sm-6 col-xl-4 service">
                    
                    <div className="icon">
                        <img src={cleanHome} alt="" />
                    </div>
                    <div className="card">
                        <div className="card-header">
                            {translate.t("CleanAstralHome")}
                        </div>
                        <div className="card-body">
                            <p>{translate.t("CleanAstralHomeText")}</p>
                            <div className="text-center">
                                <a target="_blank" href="https://laskyplnysvet.cz/blog/35-pravidelna-astralni-ocista-domacnosti" className="btn btn-primary">{translate.t("GoToWeb")}</a>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div className="col-12 col-sm-6 col-xl-4 service">
                    
                    <div className="icon">
                        <img className="individual" src={therapy} alt="" />
                    </div>
                    <div className="card">
                        <div className="card-header">
                            {translate.t("IndividualTherapy")}
                        </div>
                        <div className="card-body">
                            <p>{translate.t("IndividualTherapyText")}</p>
                        </div>
                    </div>
                    
                </div>
                <div className="col-12 col-sm-6 col-xl-4 service">
                    
                    <div className="icon">
                        <img src={astralTraveling} alt="" />
                    </div>
                    <div className="card">
                        <div className="card-header">
                            {translate.t("Astraltravel")}
                        </div>
                        <div className="card-body">
                            <p>{translate.t("AstraltravelText")}</p>
                            <div className="text-center">
                                <a target="_blank" href="https://www.astralnicestovani.cz" className="btn btn-primary">{translate.t("GoToWeb")}</a>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div className="col-12 col-sm-6 col-xl-4 service">
                    
                    <div className="icon">
                        <img src={privateGuided} alt="" />
                    </div>
                    <div className="card">
                        <div className="card-header">
                            {translate.t("PrivateGuided")}
                        </div>
                        <div className="card-body">
                            <p>{translate.t("PrivateGuidedText")}</p>
                        </div>
                    </div>
                    
                </div>
                <div className="col-12 col-sm-6 col-xl-4 service">
                    
                    <div className="icon">
                        <img src={sidrisFamily} alt="" />
                    </div>
                    <div className="card">
                        <div className="card-header">
                            {translate.t("PrivateSidrisGuided")}
                        </div>
                        <div className="card-body">
                            <p>{translate.t("PrivateSidrisGuidedText")}</p>
                        </div>
                    </div>
                 
                </div>
                <div className="col-12 col-sm-6 col-xl-4 service">
                    
                    <div className="icon">
                        <img src={becomeTherapist} alt="" />
                    </div>
                    <div className="card">
                        <div className="card-header">
                            {translate.t("BecomeTherapist")}
                        </div>
                        <div className="card-body">
                            <p>{translate.t("BecomeTherapistText")}</p>
                            <div className="text-center">
                                <a target="_blank" href="https://mujterapeut.cz/prace" className="btn btn-primary">{translate.t("GoToWeb")}</a>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div className="col-12 col-sm-6 col-xl-4 service">
                    
                    <div className="icon">
                        <img src={together} alt="" />
                    </div>
                    <div className="card">
                        <div className="card-header">
                            {translate.t("CoCreationWithTeam")}
                        </div>
                        <div className="card-body">
                            <p>{translate.t("CoCreationWithTeamText")}</p>
                            <div className="text-center">
                                <a target="_blank" href="https://www.astralniakademie.cz/hojnost" className="btn btn-primary">{translate.t("GoToWeb")}</a>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div className="col-12 col-sm-6 col-xl-4 service">
                    
                    <div className="icon">
                        <img className="individual" src={web} alt="" />
                    </div>
                    <div className="card">
                        <div className="card-header">
                            {translate.t("OwnWebsite")}
                        </div>
                        <div className="card-body">
                            <p>{translate.t("OwnWebsiteText")}</p>
                            <div className="text-center">
                                <a target="_blank" href="https://webpro.vladcesily.cz" className="btn btn-primary">{translate.t("GoToWeb")}</a>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default Intensively;